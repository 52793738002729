import React from "react";
import Press_Release_01 from "../img/Press_Release_01.jpg";
import Footer from '../footer.js';

const PressRelease = () => {
    return (
        <body className="bg-white scroll-smooth">     

            <div className="w-full h-96 overflow-hidden relative">
                <img className="absolute inset-0 h-full w-full object-cover" src={Press_Release_01} alt="" />
                <div className="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div className="flex h-full items-center justify-center relative">
                    <div className="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div className="grid grid-cols-6 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div className="col-span-6 md:col-span-6 lg:col-span-6 flex items-center">
                                <div className="py-12">
                                    <h4 className="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        PRESS
                                    </h4>
                                    {/* <p className="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                      KHK Holding, an autonomous wealth management firm, caters to a distinguished clientele comprising affluent families, individuals, and investors in the Gulf Cooperation Council (GCC) region.
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        
            <section>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    <div className="grid-cols-1 hidden">
                        <h4 className="text-24px md:text-32px lg:text-32px xl:text-5xl text-black font-secondary-medium pb-6 text-center fadein">
                            Latest Articles
                        </h4>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/IMG_4975-1-768x512.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium mt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-21, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BRAVECF-BR-1-768x512.jpeg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium mt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-23, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BOC-Gaming-1-1-600x400.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-25, 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/IMG_4975-1-768x512.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-21, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BRAVECF-BR-1-768x512.jpeg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-23, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BOC-Gaming-1-1-600x400.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-25, 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/IMG_4975-1-768x512.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-21, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BRAVECF-BR-1-768x512.jpeg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-23, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BOC-Gaming-1-1-600x400.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-25, 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="flex justify-center mt-40 mb-80 text-14px md:text-20px lg:text-30px text-gray-600 font-primary pt-3 fadein">
                        COMING SOON...
                    </p>
        
                </div>
            </section>
        
        
            <Footer />
            
        </body>
        )
}

export default PressRelease