import React from 'react';
import google_play from "./img/google_play.png";
import app_store from "./img/app_store.png";
import app_gallery from "./img/Appgallery.png";
import Brave from "./img/BRAVE TV LOGO.png";
import { FaXTwitter } from 'react-icons/fa6';
import KHKLOGO from "./img/KHK-LOGO.png";
import './footer.css';
import SubscriptionForm from './Components/subscribeForm.js';

const Footer = () => {
    return (  
        
        <div className="w-full bg-black js-show-on-scroll">
            <div className="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 pb-0">
                <div className="grid grid-cols-12 gap-5 md:gap-8">
                    <div className="col-span-7 md:col-span-7 lg:col-span-7 mt-20 pt-10">
                        <div className="pb-2">
                            <h3 className="text-16px xl:text-20px font-primary font-bold tracking-wider text-white fadein">
                                BRAVE TV APP
                            </h3>
                            {/* <hr></hr> */}
                            {/* <div className="grid grid-cols-3">
                                <div className="col-span-1"></div>
                                <div className="col-span-1"><a href="https://watch.bravecftv.com/"><img src={Brave} alt='logo' width="60px" height="50px"/></a></div>
                                <div className="col-span-1"></div>
                            </div> */}
                            <p className="text-14px xl:text-16px font-primary text-gray-300 pt-2">
                            Check out the BRAVE TV App, a major project from our portfolio. Download now for superior streaming.
                            </p>
                            <div className="grid grid-cols-3 hidden">
                                {/* <div className="col-span-1"></div> */}
                                <div className="col-span-1 xl:ml-6 mt-2"><a href="https://watch.bravecftv.com/"><img src={Brave} alt='logo' width="60px" height="50px"/></a></div>
                                {/* <div className="col-span-1"></div> */}
                            </div>
                            <div className="flex items-center pt-4">
                                <a href="https://play.google.com/store/apps/details?id=com.bravecftv&pli=1">
                                    <img src={google_play} alt="" className="mr-3" />
                                </a>
                                <a href="https://apps.apple.com/bh/app/brave-tv-mma-fights-more/id1610259996?platform=iphone">
                                    <img src={app_store} alt="" className="mr-3" />
                                </a>
                                <a href="https://appgallery.huawei.com/app/C109851473">
                                    <img src={app_gallery} alt="" />
                                </a>
                            </div>
                            <div className="pb-2 pt-4">
                                <h3 className="text-16px xl:text-20px font-primary font-bold tracking-wider uppercase text-white fadein">
                                Discover Our Projects
                                </h3>
                            </div>
                            <div className="md:flex">
                                <ul className="lg:justify-start md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://bravecf.com/" className="text-gray-300 hover:text-primary">BRAVE CF </a></li>
                                </ul>
                                <ul className="lg:justify-start md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://watch.bravecftv.com/" className="text-gray-300 hover:text-primary">BRAVE TV</a></li>
                                </ul>
                                <ul className="lg:justify-stat md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://braveathleisure.com/" className="text-gray-300 hover:text-primary">BRAVE ATHLESIURE</a></li>
                                </ul>
                            </div>
                            <div className="md:flex">
                                {/* <ul className="lg:justify-start md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://khkesports.com/" className="text-gray-300 hover:text-primary">KHK ESPORTS</a></li>
                                </ul> */}
                                <ul className="lg:justify-start md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://khkmediagroup.com/" className="text-gray-300 hover:text-primary">KHK MEDIA GROUP</a></li>
                                </ul>
                                {/* <ul className="lg:justify-start md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://khkheroes.org/" className="text-gray-300 hover:text-primary">KHK HEROES</a></li>
                                </ul> */}
                            </div>
                            <div className="md:flex">
                                {/* <ul className="lg:justify-start md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://mmasupercup.com/" className="text-gray-300 hover:text-primary">MMA SUPERCUP</a></li>
                                </ul> */}
                                {/* <ul className="lg:justify-start md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://bahrainmma.com/" className="text-gray-300 hover:text-primary">BAHRAIN MMA</a></li>
                                </ul> */}
                                {/* <ul className="lg:justify-start md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                                    <span className="mr-2 text-white">&#8226;</span>
                                    <li className="pr-4"><a href="https://khkheroes.org/" className="text-gray-300 hover:text-primary">KHK HEROES</a></li>
                                </ul> */}
                            </div>
                            {/* <div className="pb-2 pt-4">
                                <h3 className="text-16px xl:text-20px font-primary font-bold tracking-wider uppercase text-white fadein">
                                    Follow us on
                                </h3>
                            </div>
                            <div className="flex items-center justify-start">
                                <span className="pr-4">
                                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                        <span className="fa fa-instagram w-4 h-4 text-white hover:text-primary"></span>
                                    </a>
                                </span>
                                <span className="pr-2">
                                    <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                        <span className="fa fa-facebook w-4 h-4 text-white hover:text-primary"></span>
                                    </a>
                                </span>
                                <span className="pr-2">
                                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                        <span className="w-4 h-4 text-white hover:text-primary"><FaXTwitter/></span>
                                    </a>
                                </span>  
                            </div> */}
                        </div>
                    </div>
                    <div className="col-span-5 md:col-span-5 lg:col-span-5 flex flex-col">
                        <div className="">
                            {/* <div className="col-span-1"></div> */}
                            <div className="xl:ml-6 mt-0 flex justify-end"><a href="https://khkholding.com"><img src={KHKLOGO} alt='logo' class="opacity-reduced" width="" height="full"/></a></div>
                            {/* <div className="col-span-1"></div> */}
                            {/* <div class="xl:ml-0 mt-80 mr-0 flex justify-end subscribeform">
                                <form action="#" method="POST">
                                    <div class="flex">
                                        <input type="email" name="email" placeholder="Enter your email" class="py-2 pl-4 pr-14 bg-white text-gray-700 focus:outline-none focus:border-primary border border-gray-300" />
                                        <button type="submit" class="bg-primary hover:bg-yellow-700 text-white font-semibold py-2 px-8 focus:outline-none">SUBSCRIBE</button>
                                    </div>
                                </form>
                            </div> */}
                            <SubscriptionForm/>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-3 lg:col-span-3 hidden">
                        <div className="pb-2">
                            <h3 className="text-center text-16px xl:text-20px font-primary font-bold tracking-wider uppercase text-white fadein">
                                Quick Links
                            </h3>
                            <hr></hr>
                        </div>
                        <ul className="lg:justify-center md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase">
                            <span className="mr-2 text-white">&#8226;</span>
                            <li className="pr-4"><a href="https://bravecf.com/" className="text-gray-300 hover:text-primary">BRAVE CF </a></li>
                        </ul>
                        <ul className="lg:justify-center md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                            <span className="mr-2 text-white">&#8226;</span>
                            <li className="pr-4"><a href="https://watch.bravecftv.com/" className="text-gray-300 hover:text-primary">BRAVE TV</a></li>
                        </ul>
                        <ul className="lg:justify-center md:justify-start flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-3">
                            <span className="mr-2 text-white">&#8226;</span>
                            <li className="pr-4"><a href="https://braveathleisure.com/" className="text-gray-300 hover:text-primary">BRAVE ATHLESIURE</a></li>
                        </ul>
                    </div>
                    <div className="col-span-12 md:col-span-3 lg:col-span-3 hidden">
                        <div className="pb-2">
                            <h3 className="text-center text-16px xl:text-20px font-primary font-bold tracking-wider uppercase text-white fadein">
                                Follow us on
                            </h3>
                            <hr></hr>
                        </div>
                        <div className="flex items-center justify-center">
                            <span className="pr-4">
                                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                    <span className="fa fa-instagram w-4 h-4 text-white hover:text-primary"></span>
                                </a>
                            </span>
                            <span className="pr-2">
                                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                    <span className="fa fa-facebook w-4 h-4 text-white hover:text-primary"></span>
                                </a>
                            </span>
                            <span className="pr-2">
                                <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                    <span className="w-4 h-4 text-white hover:text-primary"><FaXTwitter/></span>
                                </a>
                            </span>  
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-3 lg:col-span-3 hidden">
                        <div className="pb-2">
                            <h3 className="text-center text-16px xl:text-20px font-primary font-bold tracking-wider uppercase text-white fadein">
                                PRIVACY
                            </h3>
                            <hr></hr>
                            <ul className="justify-center flex text-14px md:text-16px font-primary white-light-gray-60 uppercase mt-2">
                                <li className="pr-4"><a href="/Privacy" className="text-gray-300 hover:text-primary">PRIVACY POLICY</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-12 gap-0 md:gap-8 pb-10">
                    <div className="col-span-12 py-4">
                        <hr className="border border-gray-700" />
                        <div className="flex justify-between">
                            <div className="flex justify-start">
                                <a href="/Privacy" className="text-12px md:text-14px text-gray-400 hover:text-white font-primary tracking-wider pt-4">
                                    PRIVACY POLICY
                                </a>
                                <a href="/About" className="footerAboutus text-12px md:text-14px text-gray-400 hover:text-white font-primary tracking-wider pt-4 lg:ml-4">
                                    ABOUT
                                </a>
                                <a href="/Contact" className="footerContactus text-12px md:text-14px text-gray-400 hover:text-white font-primary tracking-wider pt-4 lg:ml-4">
                                    CONTACT
                                </a>
                            </div>
                            <div className="flex justify-end">
                                <p className="text-12px md:text-14px text-gray-400 font-primary tracking-wider pt-4 whitespace-nowrap">
                                    ©2024<span id="year"></span> KHK HOLDING
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>


    )
}

export default Footer