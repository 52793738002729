import React from "react";
import news_bg_3 from "../img/news-bg-3.jpg";
import news1 from "../img/News/news6.jpeg";
import Footer from '../footer.js';
import RecentArticlesSideBar from "../recentArticlesSideBar.js";

const News = () => {
    return (
        <body className="bg-white scroll-smooth">

            <div class="w-full h-80 overflow-hidden relative hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src={news_bg_3} alt="" />
                <div class="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div class="flex h-full items-center justify-center relative">
                    <div class="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div class="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div class="col-span-12 md:col-span-9 lg:col-span-6 flex items-center">
                                <div class="py-12">
                                    <h4 class="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        
                                    </h4>
                                    <p class="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <var><p class="text-14px md:text-16px text-yellow-600 font-bold pb-3 fadein">
                                11 Dec, 2023
                            </p></var>
                            <h1 className="text-20px md:text-24px lg:text-28px text-primary font-bold tracking-normal uppercase pb-3 text-left fadein">
                            Sports business delegations from Slovenia, Serbia and Indonesia arrive in Bahrain ahead of BRAVE CF series of events
                            </h1>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            Global Diplomatic Delegations from Slovenia, Serbia, and Indonesia land in the Kingdom of Bahrain to attend BRAVE CF 80, and the Bahrain BRAVE International Combat Week 2023 (BICW 2023).</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={news1} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                The delegates attended BRAVE CF 79 at the Khalifa Sports City in Isa Town. BRAVE CF 80 is hosted during the BRAVE International Combat Week 2023 which has a series of 3 shows between 5 December and 15 December. Also, the Slovenian National Karting Team joined the delegates from their nation to extend their support to athletes competing at the BRAVE Combat Federation (BRAVE CF).</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            BRAVE CF 79 witnessed the most raw talent in the history of the promotion competing at the highest level of the sport showcasing athletes from 14 nations who are destined to become the biggest names in the future of mixed martial arts. The organization has more diverse global talent than any MMA organization in the world, featuring nearly 800 athletes from over 80 countries across the globe.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							BRAVE CF attracts a host of diplomats, global businessmen, athletes, artists, celebrities, and more as the organization has made history as the only global mixed martial arts organization to host events in the most number of nations. Further, the organization completed yet another milestone by breaking viewership records in 2023. With over 500,000 viewers tuning in solely through its homegrown digital platforms.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							The organization broadcasts its events across 160+ countries to 848 million households, reaching audiences through various platforms and television channels. On the digital front, BRAVE CF offers live streams on its YouTube channel (@bravemmaf) and its own OTT platform, Brave TV.</p>
                        </div>
                    </div>
                </div>
            </div>

            <RecentArticlesSideBar />
            
            <div class="container mx-auto">
                <div class="py-12"><hr /></div>
            </div>


            <Footer />
            
        </body>
    )
}

export default News