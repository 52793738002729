import React from "react";
import news_bg_3 from "../img/news-bg-3.jpg";
import news1 from "../img/News/news3.jpg";
import Footer from '../footer.js';
import RecentArticlesSideBar from "../recentArticlesSideBar.js";

const News = () => {
    return (
        <body className="bg-white scroll-smooth">

            <div class="w-full h-80 overflow-hidden relative hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src={news_bg_3} alt="" />
                <div class="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div class="flex h-full items-center justify-center relative">
                    <div class="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div class="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div class="col-span-12 md:col-span-9 lg:col-span-6 flex items-center">
                                <div class="py-12">
                                    <h4 class="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        
                                    </h4>
                                    <p class="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <var><p class="text-14px md:text-16px text-yellow-600 font-bold pb-3 fadein">
                                10 Dec, 2023
                            </p></var>
                            <h1 className="text-20px md:text-24px lg:text-28px text-primary font-bold tracking-normal uppercase pb-3 text-left fadein">
                            IMMAF President Kerrith Brown Arrives in Bahrain to attend BRAVE International Combat Week
                            </h1>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            Kerrith Brown, the President of IMMAF, has arrived in the Kingdom of Bahrain to attend BRAVE CF 80 taking place on 15 December, and the IMMAF Asian Championship at BRAVE International Combat Week 2023. Brown was elected as president of the International Mixed Martial Arts Federation(IMMAF), following election by a unanimous board vote in Berlin on 20 June 2015.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={news1} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                Brown was a six-time champion of Great Britain, winning the British Judo Championships in 1979, 1980, 1982, 1983, 1987, and 1990, over three different weight categories. He represented Great Britain at the 1984 Summer Olympics in Los Angeles, where he claimed the bronze medal in the men's lightweight division, alongside Brazil's Luis Onmura. In 1986, he won the gold medal in the 71 kg weight category at the judo demonstration sport event as part of the 1986 Commonwealth Games.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            Brown is an active advocate for MMA to get Olympic recognition. Under his leadership, IMMAF has introduced a robust governance structure, establishing a democratically elected board, audited accounts, general assemblies, an anti-doping program, and a qualifying number of recognized members. While a new sport by most measures, IMMAF already has membership across 110 countries and the responsibility for organizing elite amateur world championships.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							BRAVE Combat Federation is the first global mixed martial arts organization to host an event in India. Having hosted 3 major shows in India in 2017, 2018, and 2019, combined with the strategic partnerships with MX Player and JIOTv, India remains a focus area for the fastest-growing global promotion that has hosted events in 30 nations worldwide. BRAVE CF attracts a host of diplomats, global businessmen, athletes, artists, celebrities, and more as the organization has made history as the only global mixed martial arts organization to host events in 30 different nations. The organization has more diverse global talent than any MMA organization in the world, featuring nearly 800 athletes from over 80 countries across the globe.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							Since its inception in 2016 under the leadership of His Highness Sheikh Khaled bin Hamad Al Khalifa, BRAVE CF has secured strategic relations and recognition from governments, promotions, businesses, and organizations to enhance the status of the sport and its athletes. In 2023, the organization exceeded viewership records with over 500,000 viewers tuning in solely through its homegrown digital platforms. The organization broadcasts its events across 160+ countries to 848 million households, reaching audiences through various platforms and television channels. On the digital front, BRAVE CF offers live streams on its YouTube channel (@bravemmaf) and its own OTT platform, Brave TV. In 2023, BRAVE CF expanded its reach by establishing strategic partnerships with leading OTT platforms across Asia and Africa. These include Jio and MX Player in India, Vidio in Indonesia, and Airtel TV in Nigeria. These collaborations demonstrate BRAVE CF's commitment to digitalization and its dedication to bringing its high-quality action to fans worldwide.</p>
                        </div>
                    </div>
                </div>
            </div>

            <RecentArticlesSideBar />
            
            <div class="container mx-auto">
                <div class="py-12"><hr /></div>
            </div>


            <Footer />
            
        </body>
    )
}

export default News