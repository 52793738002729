import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import Home_Banner from "../img/home/Home_01.jpg";
import brave_gold_logo from "../img/group/brave-logo-gold.png";
import khk_heros_logo from "../img/group/khk-heros.png";
import khk_mediagroup_logo from "../img/group/khk-mediagroup.png";
import Footer from '../footer.js';
import sport_icon from "../img/icons/img1.png";
import media_icon from "../img/icons/img2.png";
import real_estate_icon from "../img/icons/img3.png";
import finance_icon from "../img/icons/img4.png";
import tech_icon from "../img/icons/img5.png";
import news8 from "../img/News/news8.jpg";
import news7 from "../img/News/news7.jpg";
import news5 from "../img/News/news5.jpg";
import khkSportsIcon from "../img/group/KHK-SPORTS.png";
import wtc from "../img/News/worldtradecenter.jpg";
import './home.css';
import group_logo_1 from "../img/group/1.png";
import group_logo_2 from "../img/group/2.png";
import group_logo_3 from "../img/group/3.png";
import group_logo_4 from "../img/group/4.png";
import group_logo_5 from "../img/group/5.png";
import group_logo_6 from "../img/group/6.png";
import group_logo_7 from "../img/group/7.png";
import group_logo_8 from "../img/group/8.png";
import group_logo_9 from "../img/group/9.png";
import group_logo_10 from "../img/group/10.png";
import group_logo_11 from "../img/group/11.png";
import group_logo_12 from "../img/group/12.png";
import group_logo_13 from "../img/group/13.png";
import group_logo_14 from "../img/group/14.png";
import group_logo_15 from "../img/group/15.png";
import group_logo_16 from "../img/group/16.png";
import group_logo_17 from "../img/group/17.png";
import group_logo_18 from "../img/group/18.png";
import group_logo_19 from "../img/group/19.png";
import group_logo_20 from "../img/group/20.png";
import group_logo_21 from "../img/group/21.png";
import beach from "../img/extra/beach.jpg";
import flight from "../img/extra/flight.jpg";
import office from "../img/extra/office.jpeg";
import sea from "../img/extra/sea.jpg";
import officeVideo from "../videos/officeVideo.mp4";
import Model from "../Components/videoModal.js";
import Modal from "../Components/videoPopup.js";

const Home = () => {
    document.title = 'Home | KHK Holding';
  return (
    
    <main className="bg-white scroll-smooth">
        
        {/* <div className="w-full bg-white">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                    <div className="col-span-12 md:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                        <div>
                            <h4 className="text-24px md:text-32px lg:text-32px xl:text-5xl text-black font-secondary-medium uppercase pb-6 text-center md:text-left">
                                KHK HOLDING<br /> COMPANY
                            </h4>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left">
                                Develop Industry. Create Value. Be Global.
                            </p>
                            <div className="text-center md:text-left">
                                <a href="/Contact" className="px-6 py-3 text-12px md:text-14px font-secondary-medium rounded uppercase text-white bg-primary hover:bg-primary-yellow hover:text-black">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6 flex items-center mx-auto">
                        <div className="">
                            <img src={Home_Banner} alt="" className="rounded-lg shadow-md" />
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="w-full bg-black mainbanner">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-10 py-6 md:py-6 lg:py-16">
                <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                    <div className="col-span-12 md:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 animate-fade-right animate-delay-[10ms]">
                        <div>
                            <h1 className="text-44px md:text-42px lg:text-42px text-6xl sm:text-4xl md:text-6xl xl:text-8xl text-white md:text-white font-bold uppercase tracking-wide pb-6 text-center md:text-left">
                                KHK<p className="mt-2" > HOLDING</p>
                            </h1>
                            {/* <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left">
                                Develop Industry. Create Value. Be Global.
                            </p> */}
                            <div className="text-center md:text-left">
                                <a href="/Industries" className="px-6 py-3 text-12px md:text-14px font-secondary-medium rounded-lg uppercase border-2 border-black text-primary bg-white hover:bg-gray-300 hover:text-black">
                                    EXPLORE
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-span-12 md:col-span-6 flex items-center mx-auto">
                        <div className="">
                            <img src={Home_Banner} alt="" className="rounded-lg shadow-md" />
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="w-full pt-9">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-8xl 2xl:max-w-10xl mx-auto">
                    <div className="flex-1 md:flex items-center justify-evenly xl:pl-10 pt-0 pb-2 gap-4 bannerTiles animate-fade-up animate-delay-[10ms]">
                        <div className="pb-6 md:pb-0 transition-transform duration-500 transform hover:scale-105 cursor-pointer">
                            <div class="flex-1">
                                <div class="text-yellow-700 font-bold">01</div>
                                <div class="text-white pt-4 font-semibold">Discover a World of Opportunity</div>
                                <div class="text-white pt-2 mb-2 bannerTileText">Explore our diverse portfolio of investments across tech, finance, commodities, real estate, and more.</div>
                            </div>
                        </div>
                        <div className="pb-6 md:pb-0 lg:mt-4 xl:mt-0 transition-transform duration-500 transform hover:scale-105 cursor-pointer">
                            <div class="flex-1">
                                <div class="text-yellow-700 font-bold">02</div>
                                <div class="text-white pt-4 font-semibold">Empowering Growth, Inspiring Innovation</div>
                                <div class="text-white pt-2 mb-2 bannerTileText">Partner with us to unlock the potential of tomorrow's industries and drive sustainable progress.</div>
                            </div>
                        </div>
                        <div className="pb-6 md:pb-0 transition-transform duration-500 transform hover:scale-105 cursor-pointer">
                            <div class="flex-1">
                                <div class="text-yellow-700 font-bold">03</div>
                                <div class="text-white pt-4 font-semibold">Where Vision Meets Value</div>
                                <div class="text-white pt-2 mb-2 bannerTileText">Join us on a journey of discovery and growth as we shape the future of business and investment together.</div>
                            </div>
                        </div>
                        {/* <div className="pb-6 md:pb-0 videoThumbnail flex justify-center items-center px-20 transition-transform duration-500 transform hover:scale-105">
                            <div className="px-10">
                                <div className="px-10">
                                    <div className="bg-white px-5 py-3.5 rounded-full text-center hover:bg-gray-200 cursor-pointer">
                                        <div className=" text-primary hover:text-yellow-700"> <Modal/></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        
                
        {/* <div className="w-full bg-primary-dark">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl mx-auto">
                <div className="flex-1 md:flex items-center justify-evenly px-10 py-10 gap-4">
                    <a href="https://khk-sports.com/"><div className="pb-6 md:pb-0 fadein">
                        <img src={khkSportsIcon} alt="" className="mx-auto md:mx-0" />
                    </div></a>
                    <a href="https://bravecf.com/"><div className="pb-6 md:pb-0 fadein">
                        <img src={brave_gold_logo} alt="" className="mx-auto md:mx-0" />
                    </div></a>
                    <a href="https://www.khkheroes.org/">
                    <div className="pb-6 md:pb-0 fadein">
                        <img src={khk_heros_logo} alt="" className="mx-auto md:mx-0" />
                    </div>
                    </a>
                    <a href="https://www.khkmediagroup.com/"><div className="fadein">
                        <img src={khk_mediagroup_logo} alt="" className="mx-auto md:mx-0" />
                    </div></a>
                </div>
            </div>
        </div> */}
        

        <div className="w-full bg-white js-show-on-scroll hidden">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-10 md:py-6 lg:py-16">
                <div className="max-w-3xl flex-col mx-auto text-center">
                    <h4 className="text-24px md:text-32px lg:text-32px xl:text-5xl text-black font-secondary-medium pb-6 fadein">
                        We Build and Manage Tailored Investment Portfolios
                    </h4>
                    <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 fadein">
                    KHK Holding Company is proud of the legacy and diversification in a multitude of sectors such as Sports, Media, Trading & Retail, Finance, Medical, Technology and Real Estate.
                    </p>
                </div>
            </div>
        </div>
        
        
        <div id="counter" className="w-full js-show-on-scroll hidden">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-0 md:py-6 lg:py-12 text-center">
                <div className="grid md:flex grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 md:gap-4 xl:gap-6 justify-center">
                    <div className="border-r-0 md:border-r lg:border-r border-black">
                        <div className="text-black flex justify-center">
                            <div className="inline-grid px-6 fadein">
                                <span className="text-28px md:text-32px lg:text-40px xl:text-6xl font-secondary-medium text-primary text-center counter">8</span>
                                <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">Years Track Record</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="border-r-0 md:border-r lg:border-r border-black">
                        <div className="text-black flex justify-center">
                            <div className="inline-grid px-6 fadein">
                                <span className="text-28px md:text-32px lg:text-40px xl:text-6xl font-secondary-medium text-primary text-center counter">80</span>
                                <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">OVERALL EVENTS</span>
                            </div>
                        </div>
                    </div>

                    <div className="border-r-0 md:border-r lg:border-r border-black">
                        <div className="text-black flex justify-center">
                            <div className="inline-grid px-6 fadein">
                                <span className="text-28px md:text-32px lg:text-40px xl:text-6xl font-secondary-medium text-primary text-center counter ">180</span>
                                <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">BROADCAST IN COUNTRIES</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="">
                        <div className="text-black flex justify-center">
                            <div className="inline-grid px-6 fadein">
                                <span className="text-28px md:text-32px lg:text-40px xl:text-6xl font-secondary-medium text-primary text-center counter ">25</span>
                                <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">languages</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className="w-full bg-white pt-10 md:pt-20 lg:pt-24 xl:pt-32">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-10 py-6 md:py-6 lg:py-16 js-show-on-scroll">
                <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                    <div className="col-span-12 md:col-span-3 lg:col-span-3 xl:col-span-3 flex mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-0">
                        <div className="mt-10">
                            <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-center md:text-left fadein">
                                Project <br />Portfolios
                            </h4></var>
                            <var><p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                            We Build and Manage <br />Tailored Investment Portfolios <p className="font-secondary-medium text-6xl text-primary">&rarr;</p>
                            </p></var>
                            {/* <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                            KHK Holding creates and manages a diversified portfolio of companies and subsidiaries operating across 11 countries and a proven track record of success. KHK Holding prides itself on its culture of integrity, innovation, development, and value creation and works with only the best to maintain its position as a market leader.
                            </p> */}
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-9 lg:col-span-9 xl:col-span-9 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                        <div className="grid grid-cols-12 gap-4 md:gap-8 py-2 md:py-4 lg:py-6 xl:py-0">
                            <div className="col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-4 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                                <div className="flex-col">
                                    <img src={flight} alt="" className="fadein shadow-md h-full transition-transform duration-500 transform hover:scale-105" />
                                    <img src={beach} alt="" className="fadein shadow-md mt-4 h-full transition-transform duration-500 transform hover:scale-105" />
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-4 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                                <img src={wtc} alt="" className="fadein shadow-md h-full transition-transform duration-500 transform hover:scale-105" />
                            </div>
                            <div className="col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-4 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                                <div className="flex-col">
                                    <img src={sea} alt="" className="fadein shadow-md h-full transition-transform duration-500 transform hover:scale-105" />
                                    <img src={office} alt="" className="fadein shadow-md mt-4 h-full transition-transform duration-500 transform hover:scale-105" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-right flex justify-end md:mt-8">
                    <var><p className="text-14px md:text-16px lg:text-17px text-black font-secondary-medium pb-6 text-right flex justify-end fadein"><br></br>
                        Explore our diverse portfolio of investment sectors and discover the opportunities that await<br></br> Whether you're an entrepreneur seeking funding, an investor looking for growth, or a partner <br></br> eager to collaborate, we're here to help you navigate the exciting world of investing
                    </p></var>
                </div>
            </div>
        </div>
        

        <div className="container mx-auto">
            <div className="py-12"><hr /></div>
        </div>

        <div className="w-full bg-white py-0 md:py-0 lg:py-0 xl:py-0 js-show-on-scroll">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl ml-auto pl-6 md:pl-6 lg:pl-10 py-0 md:py-0 lg:py-0 js-show-on-scroll homeImgone">
                <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                    <div className="col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-3 flex mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-0">
                        <div className="mt-10 md:ml-10">
                            <var><h4 className="text-30px md:text-28px lg:text-28px xl:text-3xl text-white font-secondary-medium pb-6 text-center md:text-left  fadein">
                                What We Offer <span className="font-secondary-medium text-3xl text-primary">&rarr;</span>
                            </h4></var>
                            <var><p className="bannerTileText text-14px md:text-16px lg:text-17px text-white font-primary pb-6 text-center md:text-left fadein">
                            {/* We Build and Manage <br /> */}
                            we believe in the power of diversification and the potential for growth across various industries. Our strategic investments span a wide spectrum of sectors, each carefully selected for its promise, potential, and ability to drive sustainable returns.
                            </p></var>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-12 lg:col-span-9 xl:col-span-9 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                        <div className="grid grid-cols-12 gap-4 md:gap-8 py-2 md:py-4 lg:py-6 xl:py-0 md:ml-20 w-full">
                            <div className="col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-4 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 offerCol">
                                <div className="flex-col w-full">
                                    <Link to="Industries#finance" class="box flex flex-col offerOne">
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="inner-box bg-yellow-700 p-1 flex- mr-14 innerText">
                                            <div class="font-16 xl:text-20px font-secondary-semibold text-white text-center">
                                            Trading
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="Industries#realEstate" class="box flex flex-col mt-12 offerTwo">
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="inner-box bg-yellow-700 p-1 flex- mr-14 innerText">
                                            <div class="font-16 xl:text-20px font-secondary-semibold text-white text-center">
                                            Real Estate
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-4 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 offerCol">
                                <div className="flex-col w-full">
                                    <Link to="Industries#technology" class="box flex flex-col offerThree">
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="inner-box bg-yellow-700 p-1 flex- mr-14 innerText">
                                            <div class="font-16 xl:text-20px font-secondary-semibold text-white text-center">
                                            Technology
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="Industries#media" class="box flex flex-col mt-12 offerFour">
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="flex-1"></div>
                                        <div class="inner-box bg-yellow-700 p-1 flex- mr-14 innerText">
                                            <div class="font-16 xl:text-20px font-secondary-semibold text-white text-center">
                                            Media
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="w-full bg-white js-show-on-scroll hidden">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                    <div className="col-span-12 md:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                        <div>
                            <h4 className="text-24px md:text-32px lg:text-32px xl:text-5xl text-black font-secondary-medium tracking-normal pb-6 text-center md:text-left fadein">
                            DIVERSIFICATION IS THE CORE STRATEGY AND RECIPE FOR OUR SUCCESS
                            </h4>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                            Spreading our focus into a different range of sectors allows KHK Holding to accelerate the growth of our company, our partners and our community.
                            </p>
                            <div className="text-center md:text-left fadein">
                                <a href="/Industries" className="px-6 py-3 text-12px md:text-14px font-secondary-medium rounded uppercase text-white bg-primary hover:bg-primary-yellow hover:text-black">
                                    Discover our solutions
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6 flex items-center mx-auto">
                        <div className="text-center">
                            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 justify-center">
                                <div className="border-r border-b md:border-r md:border-b border-black p-6">
                                    <div className="text-black flex">
                                        <div className="inline-grid px-6 fadein">
                                            <img src={sport_icon} alt="" className="w-20 h-20"/>
                                            <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">Sports</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="border-b border-r-0 md:border-b border-black p-6">
                                    <div className="text-black flex">
                                        <div className="inline-grid px-6 fadein">
                                            <img src={media_icon} alt="" className="w-20 h-20"/>
                                            <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">Commodities</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="border-r border-b md:border-r md:border-b border-black p-6">
                                    <div className="text-black flex">
                                        <div className="inline-grid px-6 fadein">
                                            <img src={real_estate_icon} alt="" className="w-20 h-20"/>
                                            <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">Real Estate</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="border-b border-r-0 md:border-b border-black p-6">
                                    <div className="text-black flex">
                                        <div className="inline-grid px-6 fadein">
                                            <img src={finance_icon} alt="" className="w-20 h-20"/>
                                            <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">Finance</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="border-r md:border-r border-black p-6">
                                    <div className="text-black flex">
                                        <div className="inline-grid px-6 fadein">
                                            <img src={tech_icon} alt="" className="w-20 h-20"/>
                                            <span className="text-14px lg:text-16px xl:text-18px font-secondary-medium uppercase pt-2">Technology</span>
                                        </div>
                                    </div>
                                </div>
                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        {/* <div className="container mx-auto">
            <div className="py-12"><hr /></div>
        </div> */}

        <section className="w-full mt-8">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-10 py-6 md:py-6 lg:py-16 js-show-on-scroll">
                <div className="grid-cols-1">
                    <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-left fadein">
                        Our Partners
                    </h4></var>
                </div>
                <div className="max-w-5xl flex-col mx-auto text-center relative">
                    <button className="absolute top-0 right-0 px-2 py-0 border-2 border-primary  text-primary rounded-full text-lg font-bold hidden">&larr;</button>
                    <button className="absolute top-0 right-10 px-2 py-0 border-2 border-primary  text-primary rounded-full text-lg font-bold hidden">&rarr;</button>
                    <section className="py-12" id="logoMarqueeSection">
                        <div className="default-content-container flex items-center">
                            <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
                                <div className="marquee">
                                    {/* <span><img src={group_logo_1} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                    <span><img src={group_logo_2} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_3} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_4} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_5} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_6} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_7} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_8} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_9} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_10} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_11} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_12} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_13} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_14} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_21} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    {/* <span><img src={group_logo_16} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_17} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_18} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_19} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_20} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                    {/* <span><img src={group_logo_15} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                </div>
                                <div className="marquee">
                                    {/* <span><img src={group_logo_1} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                    <span><img src={group_logo_2} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_3} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_4} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_5} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_6} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_7} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_8} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_9} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_10} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_11} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_12} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_13} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_14} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_21} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    {/* <span><img src={group_logo_16} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_17} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_18} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_19} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                    <span><img src={group_logo_20} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                    {/* <span><img src={group_logo_15} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>

        <section className="w-full">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-10 py-6 md:py-6 lg:py-16 js-show-on-scroll">
                <div className="grid-cols-1">
                    <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-black font-secondary-medium pb-6 text-left fadein">
                        Expanding Horizons, Growing Possibilities
                    </h4></var>
                </div>
                <div className="locationsBg">

                </div>
            </div>
        </section>        
        
        <section className="w-full hidden">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-10 py-6 md:py-6 lg:py-16">
                
                <div className="grid-cols-1">
                    <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-left fadein">
                        Corporate News
                    </h4></var>
                </div>
                
                <div className="js-show-on-scroll grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                    <div className="flex items-center pb-6 md:pb-6">
                        <div className="flex-col">
                            <div className="fadein">
                                <img src={news8} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                            </div>
                            <div className="pt-4">
                                <a href="/NewsDetails8" className="text-16px text-black hover:text-primary font-secondary-medium mt-4 fadein">
                                BRAVE CF's sports development expansion to reach Bulgaria; Mayor of Burgas Dimitar...
                                </a>
                                {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                BRAVE Combat Federation continues to expand into Europe, with Bulgaria touted as a possible...
                                </p> */}
                                <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Feb-21, 2024</p>
                            </div>
                        </div>
                    </div>
    
                    <div className="flex items-center pb-6 md:pb-6">
                        <div className="flex-col">
                            <div className="fadein">
                                <img src={news7} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                            </div>
                            <div className="pt-4">
                                <a href="/NewsDetails7" className="text-16px text-black hover:text-primary font-secondary-medium mt-4 fadein">
                                Mayor of Ljubljana welcomes KHK Sports CEO Mohammed Shahid ahead of major...
                                </a>
                                {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                BRAVE Combat Federation will return to Slovenia for a huge spectacle on April 20. Ahead of the event’s...
                                </p> */}
                                <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Feb-02, 2024</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center pb-6 md:pb-6">
                        <div className="flex-col">
                            <div className="fadein">
                                <img src={news5} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                            </div>
                            <div className="pt-4">
                                <a href="/NewsDetails6" className="text-16px text-black hover:text-primary font-secondary-medium pt-4 fadein">
                                KHK Holding's BRAVE CF earns #1 Global Promotion Award following historic year...
                                </a>
                                {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                Bahrain’s sporting jewel BRAVE Combat Federation has been recognized by FightBook MMA as the number one Global...
                                </p> */}
                                <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Jan-15, 2024</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="max-w-3xl flex-col mx-auto text-center fadein mt-0 md:mt-10 mb-10 md:mb-0">
                    <a href="/News" className="px-6 py-3 text-12px md:text-14px font-secondary-medium rounded uppercase text-white bg-primary hover:bg-yellow-700 hover:text-white">
                        View More
                    </a>
                </div>
            </div>
        </section>

        <div className="container mx-auto">
            <div className="py-12"><hr /></div>
        </div>

        {/* <main class="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
          <h1 class="pt-2 pb-8 text-center text-5xl font-bold">Frequently Asked Questions</h1>
          <p class="pt-2 pb-8 text-center text-2xl">Still have questions?<a href="/WhatWeDo" target="_blank" rel="noopener noreferrer" class="underline text-yellow-800"> Visit Our FAQ Page.</a></p>
        
          <section class="grid grid-cols-1 gap-y-3 divide-y">
            <details open class="group py-1 text-lg">
              <summary class="flex cursor-pointer flex-row items-center justify-between py-1 font-semibold text-gray-800 marker:[font-size:0px] pt-5">
              In which jurisdictions is the Family Office regulated?
                <svg class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </summary>
              <p class="text-gray-500 pt-5">The Family Office and Petiole Asset Management AG are subject to the oversight of seven regulators in Bahrain, the Cayman Islands, Hong Kong, Saudi Arabia, Switzerland and the U.S.</p>
            </details>
        
            <details class="group py-1 text-lg">
              <summary class="flex cursor-pointer flex-row items-center justify-between py-1 font-semibold text-gray-800 marker:[font-size:0px] pt-5">
              Which Geographies Can I Invest In?
                <svg class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </summary>
              <p class="text-gray-500 pt-5">The Family Office offers access to international investment strategies to balance our clients’ exposure to local markets. Taking advantage of our global footprint, we provide clients with access to key international markets and deals across the globe including the U.S., Canada, China, Pan Asia, Europe and more.</p>
            </details>
        
            <details class="group py-1 text-lg">
              <summary class="flex cursor-pointer flex-row items-center justify-between py-1 font-semibold text-gray-800 marker:[font-size:0px] pt-5">
              What asset classes can I invest in?
                <svg class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </summary>
              <p class="text-gray-500 pt-5">The Family Office specializes in alternative investments, which include private equity, real estate, private credit, real assets and more.</p>
            </details>

            <details class="group py-1 text-lg">
              <summary class="flex cursor-pointer flex-row items-center justify-between py-1 font-semibold text-gray-800 marker:[font-size:0px] pt-5">
              Do I know which investments I will get on day one?
                <svg class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </summary>
              <p class="text-gray-500 pt-5">Investors are allocated private market deals over time. Information about past deals is available to help you make informed investment decisions.After being vetted by our experts, deals are allocated to clients based on a pre-agreed investment policy. This process ensures that you invest in high-quality deals that align with your goals and risk tolerance.</p>
            </details>

            <details class="group py-1 text-lg">
              <summary class="flex cursor-pointer flex-row items-center justify-between py-1 font-semibold text-gray-800 marker:[font-size:0px] pt-5">
              What are the fees?
                <svg class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </summary>
              <p class="text-gray-500 pt-5">The Invest Now Pay Later option is provided without any additional charges or fees.</p>
            </details>

            <details class="group py-1 text-lg">
              <summary class="flex cursor-pointer flex-row items-center justify-between py-1 font-semibold text-gray-800 marker:[font-size:0px] pt-5">
              Who Is The Family Office?
                <svg class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </summary>
              <p class="text-gray-500 pt-5">The Family Office is a wealth management firm serving ultra-high-net-worth individuals and families in the Gulf Cooperation Council states since 2004. The Family Office operates in Bahrain, Saudi Arabia and the UAE and manages $2 billion in tailored portfolio solutions. In 2020, The Family Office has transferred its international subsidiaries in Hong Kong and New York to Petiole Asset Management (AG) in Zürich, who have assumed the asset management activities of The Family Office.</p>
            </details>

            <details class="group py-1 text-lg">
              <summary class="flex cursor-pointer flex-row items-center justify-between py-1 font-semibold text-gray-800 marker:[font-size:0px] pt-5">
              What investment programs does The Family Office offer?
                <svg class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </summary>
              <p class="text-gray-500 pt-5">Depending on the risk tolerance, time horizon, financial goals and values of our clients, The Family Office builds portfolios in alternative asset classes such as private equity, private credit, real estate, financials and infrastructure. These portfolios fall under four investment programs: liquid portfolio, yielding portfolio, balanced portfolio, or growth portfolio.</p>
            </details>

            <details class="group py-1 text-lg">
              <summary class="flex cursor-pointer flex-row items-center justify-between py-1 font-semibold text-gray-800 marker:[font-size:0px] pt-5">
              Does The Family Office offer Shariah-compliant investments?
                <svg class="h-6 w-6 rotate-0 transform text-gray-400 group-open:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7"></path>
                </svg>
              </summary>
              <p class="text-gray-500 pt-5">The Family Office offers Shariah-compliant investment opportunities in multiple asset classes, vetted and approved by certified Shariah scholars.</p>
            </details>
          </section>
          <div className="container mx-auto">
            <div className="py-12"><hr /></div>
          </div>
          <div class="flex flex-col items-center justify-center">
            <p class="pt-2 pb-8 text-1xl">
              If your questions weren't addressed, don't hesitate to contact our team of experts.
            </p>
          
            <div className="text-center md:text-left">
                                <a href="/Contact" className="px-6 py-3 text-12px md:text-14px font-secondary-medium rounded uppercase text-white bg-primary hover:bg-primary-yellow hover:text-black">
                                    Contact Us
                                </a>
            </div>
          </div>

        </main> */}
        <Footer />
        
    </main>
    
  )
}

export default Home