import React from 'react';
import Footer from '../footer.js';

const privacyPolicy = () => {
    document.title = 'Privacy | KHK Holding';
  return (
    <main>
        <div className="w-full bg-white">
            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-10 md:py-6 lg:py-16">
                <div className="max-w-6xl flex-col mx-auto text-left">
                    <h4 className="text-20px md:text-30px lg:text-30px xl:text-3xl text-black font-secondary-medium pb-6 fadein">
                        PRIVACY POLICY
                    </h4>
                    <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 fadein">
                    This privacy and cookie policy (Privacy Policy) is intended to explain our processes and practices, including user and subscriber (you or your) choices and preferences, regarding the collection, use, storage, transfer, and processing of your information by or on behalf of KHK HOLDING, including our subsidiaries, affiliates, related entities, and service providers (ADM, we, us, or our).
                    Before using our related websites, including, without limitation, www.khkholding.com, any other sites operated by us for the delivery of martial arts and combat sports-related content (collectively, the Platform), and accessing the content made available via the Platform (the Content), you will be required to indicate your consent to our processing of your personal data (including your name, contact details, financial and device information) as described in this Privacy Policy.
                    Convert wherever KHK HOLDING is there to KHK HOLDING wherever applicable.
                    </p>

                    <h6 className="text-10px md:text-20px lg:text-20px xl:text-2xl text-black font-secondary-medium pb-6 fadein">
                    CONSENT TO PROCESSING LOCATION DATA
                    </h6>

                    <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 fadein">
                    By using the Platform and accessing the Content, you consent to the processing of data about your location (including details of your current location - Location Data).
                    If you have any questions or queries about this Privacy Policy, please feel free to contact info@khkholding.com.
                    </p>

                    <div>
                        <h2 className="text-10px md:text-20px lg:text-20px xl:text-2xl text-black font-secondary-medium pb-6 fadein uppercase">Information We Collect</h2>
                        <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 fadein">We may collect various types of information, including but not limited to:</p>
                        <ul>
                            <li>1. Your name</li>
                            <li>2. Contact details</li>
                            <li>3. Location data</li>
                            <li>4. Any other information you voluntarily provide</li>
                        </ul>
                    </div>
                
                    <div>
                        <h2 className="text-10px md:text-20px lg:text-20px xl:text-2xl text-black font-secondary-medium pb-6 fadein uppercase mt-6">How We Use Your Information</h2>
                        <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 fadein">We use the collected information for purposes such as:</p>
                        <ul>
                            <li>1. Providing and maintaining our website</li>
                            <li>2. Improving our services</li>
                            <li>3. Responding to your inquiries</li>
                        </ul>
                    </div>
                
                    <div>
                        <h2 className="text-10px md:text-20px lg:text-20px xl:text-2xl text-black font-secondary-medium pb-6 fadein uppercase mt-8">Contact Us</h2>
                        <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 fadein">If you have any questions or queries about this Privacy Policy, please feel free to contact us at <a href="mailto:info@khkholding.com">info@khkholding.com</a>.</p>
                    </div>
                                </div>
                            </div>
                        </div>

        <Footer />
    </main>
  )
}

export default privacyPolicy