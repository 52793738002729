import React from "react";
import news_bg_3 from "../img/news-bg-3.jpg";
import news1 from "../img/News/news1.jpg";
import Footer from '../footer.js';
import RecentArticlesSideBar from "../recentArticlesSideBar.js";

const News = () => {
    return (
        <body className="bg-white scroll-smooth">

            <div class="w-full h-80 overflow-hidden relative hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src={news_bg_3} alt="" />
                <div class="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div class="flex h-full items-center justify-center relative">
                    <div class="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div class="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div class="col-span-12 md:col-span-9 lg:col-span-6 flex items-center">
                                <div class="py-12">
                                    <h4 class="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        
                                    </h4>
                                    <p class="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <var><p class="text-14px md:text-16px text-yellow-600 font-bold pb-3 fadein">
                                8 Jan, 2024
                            </p></var>
                            <h1 className="text-20px md:text-24px lg:text-28px text-primary font-bold tracking-normal uppercase pb-3 text-left fadein">
                                KHK HOLDING'S BRAVE CF ANNOUNCES LANDMARK PARTNERSHIP WITH LEADING REGIONAL PROMOTION WFC FOR EUROPEAN DEVELOPMENT
                            </h1>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            BRAVE Combat Federation has announced a landmark partnership deal with Slovenian organization WFC aimed at accelerating the development of a mixed martial arts ecosystem in the country, with a strategy geared towards elevating the sport in the continent of Europe.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={news1} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                The most global organization in the sport, BRAVE CF holds the number one market share in Europe, having hosted events in 13 countries in the continent, including several shows in Slovenia. BRAVE CF has also played a key role in fostering the top talent worldwide coming from Europe, including Khamzat Chimaev, Ilia Topuria, Benoit St. Denis, Muhammad Mokaev, and many others. The partnership will serve to develop Slovenian talents such as Haris Aksalic and David Forster, who have excelled in BRAVE CF during 2023, as well as the formation of a European league in the foreseeable future.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            President of BRAVE Combat Federation, Mohammed Shahid celebrated the deal as another milestone in the organization’s presence in Europe and is looking forward to building a sustainable business model for mixed martial arts in the continent. ‘’BRAVE CF’s global strategy is to change mixed martial arts from an event business to a sports business model. Europe is a massive market and building a sustainable business model for the continent is key for the development of MMA worldwide. This is an exciting announcement for European fans and everyone associated with the sport in the continent. We will continue to work hard on developing the best talents in the world and give fighters from Europe the opportunity to showcase their talents on a global platform’’, said Mr. Shahid.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							Zlatko Mahic, president of WFC, spoke of his excitement at partnering up with BRAVE Combat Federation and is looking forward to lending his long-time experience with European MMA to the benefit of the partnership. ‘’I’ve been involved in the European MMA industry for a long time and we’re seeing the growth and efforts of BRAVE Combat Federation around the world. We have achieved a great deal here in Slovenia as well, so I believe that together we can take the sport to new heights here and in Europe as well. We believe this will contribute to the development of European talents and the creation of a proper sports economy for MMA in Europe. I’m excited to get started, share my experience and work together with BRAVE CF’’, commented Mr. Mahic.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							BRAVE Combat Federation is the most global MMA organization and part of the portfolio of KHK Holding companies.</p>
                        </div>
                    </div>
                </div>
            </div>

            <RecentArticlesSideBar />
            
            <div class="container mx-auto">
                <div class="py-12"><hr /></div>
            </div>


            <Footer />
            
        </body>
    )
}

export default News