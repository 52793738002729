import React from "react";
import news_bg_3 from "../img/news-bg-3.jpg";
import news1 from "../img/News/news5.jpg";
import Footer from '../footer.js';
import RecentArticlesSideBar from "../recentArticlesSideBar.js";
import RecentArticlesForLatest from "../recentArticlesForLatest.jsx";

const News = () => {
    return (
        <body className="bg-white scroll-smooth">

            <div class="w-full h-80 overflow-hidden relative hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src={news_bg_3} alt="" />
                <div class="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div class="flex h-full items-center justify-center relative">
                    <div class="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div class="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div class="col-span-12 md:col-span-9 lg:col-span-6 flex items-center">
                                <div class="py-12">
                                    <h4 class="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        
                                    </h4>
                                    <p class="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <var><p class="text-14px md:text-16px text-yellow-600 font-bold pb-3 fadein">
                                15 Jan, 2024
                            </p></var>
                            <h1 className="text-20px md:text-24px lg:text-28px text-primary font-bold tracking-normal uppercase pb-3 text-left fadein">
                            KHK Holding's BRAVE CF earns #1 Global Promotion Award following historic year
                            </h1>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            Bahrain’s sporting jewel BRAVE Combat Federation has been recognized by FightBook MMA as the number one Global Promotion in mixed martial arts following a historic 2023.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={news1} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                During last year, the organization broke the world record for being hosted in most countries and extended it – now, BRAVE CF has hosted shows in 30 different nations. The Bahraini company has also brokered gigantic broadcasting deals which have increased its viewership in almost a billion potential households, while also hosting the first international editions of the BRAVE International Combat Week.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            The biggest combat sports festival on the planet, the BICW took place outside of Bahrain for the first time last February, when Serbia played host to an incredible week with the IMMAF World Championship and a sold-out BRAVE CF 69, with 20,000 fans flocking the Stark Arena. In the main event of the evening, Abdisalam Kubanychbek unified the Lightweight world title in a huge performance against a very tough Kamil Magomedov.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							The BICW then had its first edition in South America, once again in association with IMMAF, which hosted their Pan-American Championships – BRAVE CF 73 headlined the Pan-American BICW again with a world title on the line – the vacant Bantamweight championship was won by Jose Torres in a blockbuster fight against Nkosi Ndebele (who would later go on to claim the title in the rematch).</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							On two occasions, BRAVE CF broke the world record for host countries by a mixed martial arts organization – first by reaching 29 nations with BRAVE CF 74, in France; and then extending it by 30 nations, with Spain at BRAVE CF 75. Another milestone in 2023 was the 800th athlete that has fought for BRAVE CF, with the organization now having given global opportunities to 821 fighters from 88 different countries, highlighting its international reach.</p>
                        </div>
                    </div>
                </div>
            </div>

            <RecentArticlesForLatest />
            
            <div class="container mx-auto">
                <div class="py-12"><hr /></div>
            </div>


            <Footer />
            
        </body>
    )
}

export default News