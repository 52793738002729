import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import React, { useEffect } from "react";
// import { Link, scroller } from 'react-scroll';
import Home from './Home/home.jsx';
import About from './About/About.jsx';
import News from './News/News.jsx';
import NewsDetails from './News/NewsDetails.jsx';
import NewsDetails2 from './News/NewsDetails2.jsx';
import NewsDetails3 from './News/NewsDetails3.jsx';
import NewsDetails4 from './News/NewsDetails4.jsx';
import NewsDetails5 from './News/NewsDetails5.jsx';
import NewsDetails6 from './News/NewsDetails6.jsx';
import NewsDetails7 from './News/NewsDetails7.jsx';
import NewsDetails8 from './News/NewsDetails8.jsx';
import Industries from './Industries/Industries.jsx';
import Contact from './Contact/Contact.jsx';
import ChairmanMessage from './ChairmanMessage/ChairmanMessage.jsx';
// import BoardDirectors from './BoardDirectors/BoardDirectors.jsx';
// import WhatWeDo from './WhatWeDo/WhatWeDo.jsx';
import PressRelease from './PressRelease/PressRelease.jsx';
import Privacy from './Privacy/privacyPolicy.jsx';

import '../node_modules/jquery/dist/jquery.min.js';
import '../node_modules/preline/dist/preline.js';
import $ from "jquery";
import holdinglogo from "./img/logo03.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import toast, { Toaster } from 'react-hot-toast';
//const notify = () => toast('Something went wrong.');

/*const App = () => {
  return (
    <div>
      <button onClick={notify}>Make me a toast</button>
      <Toaster />
    </div>
  );
};*/



function App() {

    //const notify = () => toast("Something went wrong!");

  useEffect(() => {

    const contactForm = document.querySelector('.contact-form');
     const notify = () => toast('Something went wrong!');
     


    let name = document.getElementById('name');
    let email = document.getElementById('email');
    //let subject = document.getElementById('subject');
    let message = document.getElementById('message');
    let phone = document.getElementById('phone');
    let country = document.getElementById('country');

    window.onload = function(){
       if(contactForm){ 
        contactForm.addEventListener('submit', (e)=>{
            e.preventDefault();
    
            let formData = {
                name: name.value,
                email: email.value,
                //subject: subject.value,
                message: message.value,
                phone: phone.value,
                country: country.value
            }
    
            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/send-mail');
            xhr.setRequestHeader('content-type', 'application/json');
            xhr.onload = function(){
                console.log(xhr.responseText);
                if(xhr.responseText === 'success'){
                    alert("email sent");
                    name.value ='';
                    email.value='';
                    //subject.value='';
                    message.value='';
                    phone.value='';
                    country.value='';
                }else{
                    //alert('Something is wrong')
                    //{notify}
                    //<ToastContainer/>
                    notify();
                    console.log(notify)
                   
                }
                return(
                    <ToastContainer/>
                )
            }
            xhr.send(JSON.stringify(formData))
            console.log(formData)
    
        })}
    }

    /* Nav Bar 

    function toggleNavbar(collapseID) {
      document.getElementById(collapseID).classList.toggle("hidden");
      document.getElementById(collapseID).classList.toggle("block");
    }

    /* Nav Bar */

    const callback = function (entries) {
      entries.forEach((entry) => {
          //console.log(entry);
  
          if (entry.isIntersecting) {
              entry.target.classList.add("animate-fadeIn");
          } else {
              entry.target.classList.remove("animate-fadeIn");
          }
      });
    };
  
    const observer = new IntersectionObserver(callback);
    
    const targets = document.querySelectorAll(".js-show-on-scroll");
    targets.forEach(function (target) {
        target.classList.add("opacity-0");
        observer.observe(target);
    });

    $(document).on("scroll", function () {
      var pageTop = $(document).scrollTop()
      var pageBottom = pageTop + $(window).height()
      var tags = $(".fadein")
  
      for (var i = 0; i < tags.length; i++) {
        var tag = tags[i]
  
        if ($(tag).offset().top < pageBottom) {
          $(tag).addClass("visible")
        } else {
          $(tag).removeClass("visible")
        }
      }
    })
  },[])

  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
     //Java Logic here
     <>
     {/* //html logic here */}
    <Router>
        <div className="bg-white scroll-smooth sticky top-0 z-20">

            <div className="w-full relative">
                <div className="w-full mx-auto justify-between bg-black">

                    <nav className="pb-0 md:pb-0 lg:pb-2 pt-2 relative text-white" class="navbar">
                        <div className="md:content-center grid grid-cols-12 gap-4 items-center justify-between max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto" id="navbarNavDropdown">
                            <div className="col-span-12 md:col-span-5 lg:col-span-5 xl:col-span-5 flex md:flex pr-6 pl-6 md:pr-12 md:pl-12 lg:pr-0 lg:pl-0 pb-0 lg:pb-0">
                                <div className="logo">
                                    <a href="/">
                                        <img src={holdinglogo} alt="KHK Holding LOGO" itemprop="logo" className="h-24" class="logoimg" />
                                        {/* <img src={holdinglogo} alt="Girl in a jacket" width="500" height="600"></img> */}
                                    </a>
                                </div>
                                <button className="block border-2 border-primary cursor-pointer lg:hidden my-auto px-2 py-1 text-xl ml-auto" type="button" data-hs-collapse="#example-collapse-navbar" aria-controls="example-collapse-navbar" aria-label="Toggle navigation">
                                    <i className="text-primary fa fa-bars"></i>
                                </button>
                            </div>
                            <div className="col-span-12 md:col-span-7 lg:col-span-7 xl:col-span-7 hidden lg:flex flex-grow items-center lg:mx-auto bg-gray-500 lg:bg-black mt-5 lg:mb-9" id="example-collapse-navbar">
                                <ul className="flex flex-col lg:flex-row md:text-base sm:text-sm md:ml-10 mt-4 md:mt-4 lg:ml-0 lg:mt-0 pl-6 md:pl-0">
                                    <li className="flex items-center mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-white hover:text-primary">
                                        <NavLink to="/" className="underline-on-active">
                                            <span className="last firstlevel" activeClassName="text-yellow-700">Home</span>
                                        </NavLink>
                                    </li>
                                    <li className="flex items-center mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-white hover:text-primary">
                                        <NavLink to="/About" className="underline-on-active">
                                            <span className="last firstlevel">About</span>
                                        </NavLink>
                                    </li>
                                    <li className="hidden flex items-center hs-dropdown [--strategy:static] sm:[--strategy:absolute] [--adaptive:none]">
                                        <button type="button" className="flex items-center mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-white hover:text-primary">
                                            About
                                            <svg className="ml-2 w-2.5 h-2.5 text-white" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
                                            </svg>
                                        </button>

                                        <div className="bg-gray-300 hs-dropdown-menu transition-[opacity,margin] sm:border duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 w-full hidden z-10 top-full left-0 min-w-[15rem] sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute before:-top-5 before:left-0 before:w-full before:h-5">
                                            <div className="sm:grid sm:grid-cols-4 gap-4">
                                                <div className="flex flex-col">
                                                    <p className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary">
                                                        
                                                    </p>
                                                    <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    
                                                    </p>
                                                </div>
                                                <div className="flex flex-col">
                                                    <a className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary" href="/About">
                                                        About Us
                                                    </a>
                                                    {/* <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    Find out more about KHK Holding’s mission and ethos.
                                                    </p> */}
                                                </div>
                                                <div className="flex flex-col">
                                                    <a className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary" href="/ChairmanMessage">
                                                    CHAIRMAN’S MESSAGE
                                                    </a>
                                                    {/* <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    Read about KHK Holding’s principles straight from the CEO.
                                                    </p> */}
                                                </div>
                                                {/* <div className="flex flex-col">
                                                    <a className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary" href="/BoardDirectors">
                                                    BOARD OF DIRECTORS
                                                    </a>
                                                    <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    Meet our independent board of directors with a proven track record
                                                    </p>
                                                </div> */}
                                            </div>
                                        </div>
                                    </li>
                                    <li className="group flex items-center mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-white hover:text-primary">
                                        <NavLink to="/News" className="underline-on-active">
                                            <span className="firstlevel">Multimedia</span>
                                        </NavLink>
                                    </li>
                                    {/* <li className=" flex items-center hs-dropdown [--strategy:static] sm:[--strategy:absolute] [--adaptive:none]">
                                        
                                        <button type="button" className="flex items-center mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-white hover:text-primary">
                                        INDUSTRIES
                                            <svg className="ml-2 w-2.5 h-2.5 text-white" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round"></path>
                                            </svg>
                                        </button>
                                                 
                                        <div className="hs-dropdown-menu transition-[opacity,margin] sm:border duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 w-full hidden z-10 top-full left-0 min-w-[15rem] bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute before:-top-5 before:left-0 before:w-full before:h-5">
                                            <div className="sm:grid sm:grid-cols-5 gap-4">
                                                <div className="flex flex-col">
                                                    <a href="/Industries#sports" className="smooth-scroll"><span className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary" href="/About">
                                                        Sports
                                                    </span></a>
                                                    <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    In the realm of sports, KHK Holding leads with a visionary approach to investments.
                                                    </p>
                                                </div>
                                                <div className="flex flex-col">
                                                    <a href="/Industries#media" className="smooth-scroll"><span className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary" href="/ChairmanMessage">
                                                    Media
                                                    </span></a>
                                                    <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    KHK Holding recognizes the transformative power of media in today's interconnected world.
                                                    </p>
                                                </div>
                                                <div className="flex flex-col">
                                                    <a href="/Industries#realEstate" className="smooth-scroll"><span className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary" href="/BoardDirectors">
                                                    Real Estate
                                                    </span></a>
                                                    <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    As a key player in real estate, KHK Holding is dedicated to shaping urban landscapes and creating vibrant communities.
                                                    </p>
                                                </div>
                                                <div className="flex flex-col">
                                                    <a href="/Industries#finance" className="smooth-scroll"><span className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary" href="/BoardDirectors">
                                                    Finance
                                                    </span></a>
                                                    <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    In the finance sector, KHK Holding excels in navigating the complexities of global markets.
                                                    </p>
                                                </div>
                                                <div className="flex flex-col">
                                                    <a href="/Industries#technology" className="smooth-scroll"><span className="flex items-center gap-x-3.5 py-2 px-3 mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-black hover:text-primary" href="/BoardDirectors">
                                                    Technology
                                                    </span></a>
                                                    <p className="text-14px text-black font-primary pb-3 md:pb-6 gap-x-3.5 py-0 md:py-2 pl-5">
                                                    Embracing the digital frontier, KHK Holding is at the forefront of technology investments.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </li> */}
                                    {/* <li className="flex items-center mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-white hover:text-primary">
                                        <a href="/PressRelease">
                                            <span className="last firstlevel">Press</span>
                                        </a>
                                    </li> */}
                                    <li className="flex items-center mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-white hover:text-primary">
                                        <NavLink to="/Contact" className="underline-on-active">
                                            <span className="last firstlevel">Contact</span>
                                        </NavLink>
                                    </li>
                                    {/* <li className="flex items-center mb-1 md:mb-0 mx-2 relative pr-2 xl:pr-4 3xl:pr-5 pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium tracking-widest rounded uppercase text-white hover:text-primary">
                                        <a href="/WhatWeDo">
                                            <span className="last firstlevel">What We Do</span>
                                        </a>
                                    </li> */}
                                    
                                </ul>
                            </div>
                            {/* <div className="mt-5 col-span-12 md:col-span-12 lg:col-span-2 xl:col-span-2 lg:flex flex-grow items-center ml-auto lg:mb-8">
                                <ul>
                                    <li className="flex items-center mb-1 md:mb-0 mx-2 relative pb-2 lg:pb-0 text-12px md:text-14px font-secondary-medium rounded uppercase text-white bg-primary hover:bg-primary-yellow hover:text-black">
                                        <a href="/Contact" className="px-5 lg:px-3 xl:px-5 py-2">
                                            <span className="last firstlevel whitespace-nowrap">Contact Us</span>
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                            
                        </div>
                    </nav>
                    
                </div>
            </div>

        </div>

       
       {/* <Home/> */}
       <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/News" element={<News />}></Route>
          <Route path="/NewsDetails" element={<NewsDetails />}></Route>
          <Route path="/NewsDetails2" element={<NewsDetails2 />}></Route>
          <Route path="/NewsDetails3" element={<NewsDetails3 />}></Route>
          <Route path="/NewsDetails4" element={<NewsDetails4 />}></Route>
          <Route path="/NewsDetails5" element={<NewsDetails5 />}></Route>
          <Route path="/NewsDetails6" element={<NewsDetails6 />}></Route>
          <Route path="/NewsDetails7" element={<NewsDetails7 />}></Route>
          <Route path="/NewsDetails8" element={<NewsDetails8 />}></Route>
          <Route path="/Industries" element={<Industries />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/ChairmanMessage" element={<ChairmanMessage />}></Route>
          {/* <Route path="/BoardDirectors" element={<BoardDirectors />}></Route> */}
          {/* <Route path="/WhatWeDo" element={<WhatWeDo />}></Route> */}
          <Route path="/PressRelease" element={<PressRelease />}></Route>
          <Route path="/Privacy" element={<Privacy />}></Route>
          
         {/* <Route {...isAuthenticated && <UserOptions user={user} />}/> */}
         {/* <Route path="/profile" element={<Profile />} /> */}
         {/* <Route path="/Signin" element={<Signin/>}></Route> */}
         {/* <Route path="/Signup" element={<Signup/>}></Route> */}
         {/* <Route path="/admin/Signup" element={<Signin/>}></Route> */}
         {/* <Route path="/profile" element={<ProtectedRoute />}></Route> */}
         {/* <Route path='*' element={<HomePage />} />
         <Route path="/signup" element={<Signup register={registerHandler}></Signup>}></Route>
         <Route path="/signin" element={token !== null ? <HomePage></HomePage> : <Signin ></Signin>} /> */}
         {/* <Route path="/product/:id" element={<ProductDetails />}></Route>
         <Route path="/me/update" element={<UpdateProfile />}></Route>
         <Route path="/password/update" element={<UpdatePassword />}></Route>
         <Route path="/password/forgot" element={<ForgotPassword />}></Route>
         <Route path="/password/reset/:token" element={<ResetPassword />}></Route>
         <Route path="/about" element={<About />}></Route>
         <Route path="/shop" element={<Shop />}></Route>
         <Route path="/search" element={<Search />}></Route>
         <Route path="/shop/:keyword" element={<Shop />}></Route>
         <Route path="/cart" element={<Cart />}></Route> */}
       </Routes>
    </Router>
   </>
  );
}

export default App;
