import React, { useState } from 'react';
import officeVideo from "../videos/officeVideo.mp4";

function VideoPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div>
      <button onClick={handlePlay} disabled={isPlaying}>
        {isPlaying ? "" : <i className="fa fa-play fa-2x" />}
      </button>
      {isPlaying && (
        <video
          width="640"
          height="360"
          controls
          autoPlay
          onEnded={() => setIsPlaying(false)}
        >
          <source src={officeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default VideoPlayer;