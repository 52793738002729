import React from 'react';
import news8 from "../src/img/News/news8.jpg";
import news7 from "../src/img/News/news7.jpg";
import news6 from "../src/img/News/news5.jpg";

const RecentArticlesSideBar = () => {
  return (
    <div class="col-span-12 md:col-span-3 flex-1 mx-auto md:mx-0 pb-6 md:pb-0 px-6 border-l-2 border-gray-500">
        <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
            <div className="grid-cols-1">
                <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-0 text-left fadein">
                    Recent Articles
                </h4></var>
            </div>
        </div>
        <div className="js-show-on-scroll grid grid-cols-1 mx-auto px-6 md:px-6 md:grid-cols-3 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-4">
            <div className="flex items-center pb-6 md:pb-6">
                <div className="flex-col">
                    <div className="fadein">
                        <img src={news8} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                    </div>
                    <div className="pt-4 text-center">
                        <a href="/NewsDetails8" className="text-16px text-primary hover:text-primary-yellow font-bold uppercase mt-4 fadein">
                        BRAVE CF's sports development expansion to reach Bulgaria...
                        </a>
                        {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                        BRAVE Combat Federation has announced a landmark partnership deal with Slovenian organization WFC aimed at accelerating...
                        </p> */}
                        <p className="text-14px md:text-16px text-gray-800 font-primary pt-1 mb-6 fadein text-center">Feb 21, 2024</p>
                    </div>
                </div>
            </div>
            <div className="flex items-center pb-6 md:pb-6">
                <div className="flex-col">
                    <div className="fadein">
                        <img src={news7} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                    </div>
                    <div className="pt-4 text-center">
                        <a href="/NewsDetails7" className="text-16px text-primary hover:text-primary-yellow font-bold uppercase mt-4 fadein">
                        Mayor of Ljubljana welcomes KHK Sports CEO Mohammed...
                        </a>
                        {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                        BRAVE Combat Federation has announced a landmark partnership deal with Slovenian organization WFC aimed at accelerating...
                        </p> */}
                        <p className="text-14px md:text-16px text-gray-800 font-primary pt-1 mb-6 fadein text-center">Feb 02, 2024</p>
                    </div>
                </div>
            </div>
            <div className="flex items-center pb-6 md:pb-6">
                <div className="flex-col">
                    <div className="fadein">
                        <img src={news6} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                    </div>
                    <div className="pt-4 text-center">
                        <a href="/NewsDetails6" className="text-16px text-primary hover:text-primary-yellow font-bold uppercase mt-4 fadein">
                        KHK Holding's BRAVE CF earns #1 Global Promotion Award...
                        </a>
                        {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                        BRAVE Combat Federation has announced a landmark partnership deal with Slovenian organization WFC aimed at accelerating...
                        </p> */}
                        <p className="text-14px md:text-16px text-gray-800 font-primary pt-1 mb-6 fadein text-center">Jan 15, 2024</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-6 px-6 hidden">
            <div className="flex items-center pb-6 md:pb-6">
                <div className="flex">
                    <div class="flex-col mx-6">
                        <div className="fadein">
                            <img src={news8} alt="" className="rounded-lg shadow-md"/>
                        </div>
                        <div className="pt-4">
                            <a href="/NewsDetails8" className="text-16px text-primary hover:text-primary-yellow font-bold uppercase mt-4 fadein">
                            BRAVE CF's sports development expansion to reach Bulgaria...
                            </a>
                            {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                            BRAVE Combat Federation has announced a landmark partnership deal with Slovenian organization WFC aimed at accelerating...
                            </p> */}
                            <p className="text-14px md:text-16px text-gray-800 font-primary pt-1 mb-6 fadein text-center">Feb-21, 2024</p>
                        </div>
                    </div>
                    <div class="flex-col mx-6">
                        <div className="fadein">
                            <img src={news7} alt="" className="rounded-lg shadow-md"/>
                        </div>
                        <div className="pt-4">
                            <a href="/NewsDetails7" className="text-16px text-primary hover:text-primary-yellow font-bold uppercase mt-4 fadein">
                            Mayor of Ljubljana welcomes KHK Sports CEO Mohammed...
                            </a>
                            {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                            BRAVE Combat Federation has announced a landmark partnership deal with Slovenian organization WFC aimed at accelerating...
                            </p> */}
                            <p className="text-14px md:text-16px text-gray-800 font-primary pt-1 mb-6 fadein text-center">Feb-02, 2024</p>
                        </div>
                    </div>
                    <div class="flex-col mx-6">
                        <div className="fadein">
                            <img src={news6} alt="" className="rounded-lg shadow-md"/>
                        </div>
                        <div className="pt-4">
                            <a href="/NewsDetails6" className="text-16px text-primary hover:text-primary-yellow font-bold uppercase mt-4 fadein">
                            KHK Holding's BRAVE CF earns #1 Global Promotion Award...
                            </a>
                            {/* <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                            BRAVE Combat Federation has announced a landmark partnership deal with Slovenian organization WFC aimed at accelerating...
                            </p> */}
                            <p className="text-14px md:text-16px text-gray-800 font-primary pt-1 mb-6 fadein text-center">Jan-15, 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="border-b-2 py-6">
            <a href="/" class="text-14px md:text-16px font-primary text-gray-700 pb-3 capitalize fadein hover:text-primary font-medium">
                BRAVE Combat Federation Joins Forces With The National Handball Federation
            </a>
        </div>
        <div class="border-b-2 py-6">
            <a href="/" class="text-14px md:text-16px font-primary text-gray-700 pb-3 capitalize fadein hover:text-primary font-medium">
                BRAVE Combat Federation Joins Forces With The National Handball Federation
            </a>
        </div>
        <div class="border-b-2 py-6">
            <a href="/" class="text-14px md:text-16px font-primary text-gray-700 pb-3 capitalize fadein hover:text-primary font-medium">
                BRAVE Combat Federation Joins Forces With The National Handball Federation
            </a>
        </div>
        <div class="border-b-2 py-6">
            <a href="/" class="text-14px md:text-16px font-primary text-gray-700 pb-3 capitalize fadein hover:text-primary font-medium">
                BRAVE Combat Federation Joins Forces With The National Handball Federation
            </a>
        </div>
        <div class="border-b-2 py-6">
            <a href="/" class="text-14px md:text-16px font-primary text-gray-700 pb-3 capitalize fadein hover:text-primary font-medium">
                BRAVE Combat Federation Joins Forces With The National Handball Federation
            </a>
        </div>  */}
    </div>
  )
}

export default RecentArticlesSideBar