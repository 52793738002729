import React, { useState } from 'react';
import axios from 'axios';

function SubscriptionForm() {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [formStatus, setFormStatus] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(true);
    setEmailError(false);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      try {
        const response = await axios.post('https://braveathleisure.com/api/v1/emails', {
          email: email,
        });

        if (response && response.status === 201) {
            // console.log('Email sent successfully:', email);
          setFormStatus('success');
          setEmail('');
        } else {
          setFormStatus('error');
          console.error('Failed to save email:', response);
        }
      } catch (error) {
        setFormStatus('error');
        console.error('Error saving email:', error);
      }
    } else {
      setIsValidEmail(false);
      setEmailError(true);
    }
  };

  return (
    <div className="xl:ml-0 mt-80 mr-0 flex justify-end subscribeform">
      <form onSubmit={handleSubmit}>
        <div className="flex">
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            className="py-2 pl-4 pr-14 bg-white text-gray-700 focus:outline-none focus:border-primary border border-gray-300"
            value={email}
            onChange={handleEmailChange}
          />
          <button
            type="submit"
            className="bg-primary hover:bg-yellow-700 text-white font-semibold py-2 px-8 focus:outline-none"
          >
            SUBSCRIBE
          </button>
        </div>
        {!isValidEmail && emailError && <p style={{ color: 'red' }}>Please enter a valid email address.</p>}
        {formStatus === 'success' && <p style={{ color: 'gold' }}>Thank you for subscribing!</p>}
        {formStatus === 'error' && <p style={{ color: 'red' }}>Failed to subscribe. Please try again.</p>}
      </form>
    </div>
  );
}

export default SubscriptionForm;
