import React from "react";
import News_01 from "../img/News_01.jpg";
import news1 from "../img/News/news1.jpg";
import news2 from "../img/News/news2.jpg";
import news3 from "../img/News/immafPresident.jpg";
import news4 from "../img/News/news4.webp";
import news6 from "../img/News/news6.jpeg";
import news5 from "../img/News/news5.jpg";
import news7 from "../img/News/news7.jpg";
import news8 from "../img/News/news8.jpg";
import Footer from '../footer.js';

const News = () => {
    document.title = 'Multimedia | KHK Holding';
    return (
        <main className="bg-white scroll-smooth">

            <div className="w-full h-96 overflow-hidden relative hidden">
                <img className="absolute inset-0 h-full w-full object-cover" src={News_01} alt="" />
                <div className="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div className="flex h-full items-center justify-center relative">
                    <div className="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div className="grid grid-cols-6 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div className="col-span-6 md:col-span-6 lg:col-span-6 flex items-center">
                                <div className="py-12">
                                    <h4 className="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        MULTIMEDIA
                                    </h4>
                                    {/* <p className="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                    KHK Holding stands as an autonomous wealth management firm, dedicated to serving the most affluent families, individuals, and investors within the Gulf Cooperation Council (GCC).
                                   </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        
            <section>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    <div className="grid-cols-1">
                        <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-left fadein">
                            Corporate News
                        </h4></var>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="flex items-center pb-6 md:pb-6">
                            <div className="flex-col">
                                <a href="/NewsDetails8" className="fadein">
                                    <img src={news8} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                                </a>
                                <div className="pt-4 text-center">
                                    <a href="/NewsDetails8" className="text-16px text-primary hover:text-yellow-700 font-bold mt-4 fadein uppercase">
                                    BRAVE CF's sports development expansion to reach Bulgaria;...
                                    </a>
                                    {/* <p className="text-10px md:text-12px lg:text-14px text-gray-600 font-primary pt-3 fadein">
                                    BRAVE Combat Federation continues to expand into Europe,...
                                    </p> */}
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-1 fadein">Feb 21, 2024</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6">
                            <div className="flex-col">
                                <a href="/NewsDetails7" className="fadein">
                                    <img src={news7} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                                </a>
                                <div className="pt-4 text-center">
                                    <a href="/NewsDetails7" className="text-16px text-primary hover:text-yellow-700 font-bold mt-4 fadein uppercase">
                                    Mayor of Ljubljana welcomes KHK Sports CEO...
                                    </a>
                                    {/* <p className="text-10px md:text-12px lg:text-14px text-gray-600 font-primary pt-3 fadein">
                                    BRAVE Combat Federation will return to Slovenia for...
                                    </p> */}
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-1 fadein">Feb 02, 2024</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center pb-6 md:pb-6">
                            <div className="flex-col">
                                <a href="/NewsDetails6" className="fadein">
                                    <img src={news5} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                                </a>
                                <div className="pt-4 text-center">
                                    <a href="/NewsDetails6" className="text-16px text-primary hover:text-yellow-700 font-bold mt-4 fadein uppercase">
                                    KHK Holding's BRAVE CF earns #1 Global Promotion
                                    </a>
                                    {/* <p className="text-10px md:text-12px lg:text-14px text-gray-600 font-primary pt-3 fadein">
                                    Bahrain’s sporting jewel BRAVE Combat Federation...
                                    </p> */}
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-1 fadein">Jan 15, 2024</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="flex items-center pb-6 md:pb-6">
                            <div className="flex-col">
                                <a href="/NewsDetails" className="fadein">
                                    <img src={news1} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                                </a>
                                <div className="pt-4 text-center">
                                    <a href="/NewsDetails" className="text-16px text-primary hover:text-yellow-700 font-bold mt-4 fadein uppercase">
                                    KHK Holding's BRAVE CF announces landmark...
                                    </a>
                                    {/* <p className="text-10px md:text-12px lg:text-14px text-gray-600 font-primary pt-3 fadein">
                                    BRAVE Combat Federation has announced a landmark...
                                    </p> */}
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-1 fadein">Jan 8, 2024</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6">
                            <div className="flex-col">
                                <a href="/NewsDetails2" className="fadein">
                                    <img src={news2} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                                </a>
                                <div className="pt-4 text-center">
                                    <a href="/NewsDetails2" className="text-16px text-primary hover:text-yellow-700 font-bold mt-4 fadein uppercase">
                                    Asian Cricket Council President and BCCI Honorary...
                                    </a>
                                    {/* <p className="text-10px md:text-12px lg:text-14px text-gray-600 font-primary pt-3 fadein">
                                    Manama, Bahrain: Jay Shah, President of the Asian Cricket...
                                    </p> */}
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-1 fadein">Dec 26, 2023</p>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center pb-6 md:pb-6">
                            <div className="flex-col">
                                <a href="/NewsDetails5" className="fadein">
                                    <img src={news6} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                                </a>
                                <div className="pt-4 text-center">
                                    <a href="/NewsDetails5" className="text-16px text-primary hover:text-yellow-700 font-bold mt-4 fadein uppercase">
                                    Sports business delegations from Slovenia, Serbia... 
                                    </a>
                                    {/* <p className="text-10px md:text-12px lg:text-14px text-gray-600 font-primary pt-3 fadein">
                                    Global Diplomatic Delegations from Slovenia, Serbia, and...
                                    </p> */}
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-1 fadein">Dec 11, 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0 md:px-24">
                        <div className="flex items-center pb-6 md:pb-6 md:pl-16">
                            <div className="flex-col">
                                <a href="/NewsDetails4" className="fadein">
                                    <img src={news3} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                                </a>
                                <div className="pt-4 text-center">
                                    <a href="/NewsDetails4" className="text-16px text-primary hover:text-yellow-700 font-bold mt-4 fadein uppercase">
                                    IMMAF President Kerrith Brown Arrives in Bahrain...
                                    </a>
                                    {/* <p className="text-10px md:text-12px lg:text-14px text-gray-600 font-primary pt-3 fadein">
                                    Kerrith Brown, the President of IMMAF, has arrived in the...
                                    </p> */}
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-1 fadein">Dec 10, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 md:pr-16">
                            <div className="flex-col">
                                <a href="/NewsDetails3" className="fadein">
                                    <img src={news4} alt="" className="rounded-lg shadow-md transition-transform duration-500 transform hover:scale-105"/>
                                </a>
                                <div className="pt-4 text-center">
                                    <a href="/NewsDetails3" className="text-16px text-primary hover:text-yellow-700 font-bold mt-4 fadein uppercase">
                                    German football legend Jérôme Boateng announces...
                                    </a>
                                    {/* <p className="text-10px md:text-12px lg:text-14px text-gray-600 font-primary pt-3 fadein">
                                    German professional footballer, Jérôme Boateng lands in...
                                    </p> */}
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-1 fadein">Dec 10, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BOC-Gaming-1-1-600x400.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-8px md:text-10px text-gray-800 font-primary pt-3 fadein">Sep-25, 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/IMG_4975-1-768x512.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-21, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BRAVECF-BR-1-768x512.jpeg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-23, 2023</p>
                                </div>
                            </div>
                        </div>
        
                        <div className="flex items-center pb-6 md:pb-6 hidden">
                            <div className="flex-col">
                                <div className="fadein">
                                    <img src="http://khkholding.com.bh/wp-content/uploads/2020/10/BOC-Gaming-1-1-600x400.jpg" alt="" className="rounded-lg shadow-md"/>
                                </div>
                                <div className="pt-4">
                                    <a href="/NewsDetails" className="text-16px text-black font-secondary-medium pt-4 fadein">
                                        BRAVE Combat Federation Joins Forces With The National Handball Federation
                                    </a>
                                    <p className="text-14px md:text-16px lg:text-17px text-gray-600 font-primary pt-3 fadein">
                                        An official partnership has been signed between His Highness Sheikh Khaled Bin Hamad Al Khalifa’s sports…
                                    </p>
                                    <p className="text-14px md:text-16px text-gray-800 font-primary pt-3 fadein">Sep-25, 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
        
                </div>
            </section>
        
        
            <Footer />
            
        </main>
        )
}

export default News