import React from "react";
import news_bg_3 from "../img/news-bg-3.jpg";
import news1 from "../img/News/news8.jpg";
import Footer from '../footer.js';
import RecentArticlesSideBar from "../recentArticlesSideBar.js";
import RecentArticlesForLatest from "../recentArticlesForLatest.jsx";

const News = () => {
    return (
        <body className="bg-white scroll-smooth">

            <div class="w-full h-80 overflow-hidden relative hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src={news_bg_3} alt="" />
                <div class="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div class="flex h-full items-center justify-center relative">
                    <div class="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div class="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div class="col-span-12 md:col-span-9 lg:col-span-6 flex items-center">
                                <div class="py-12">
                                    <h4 class="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        
                                    </h4>
                                    <p class="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <var><p class="text-14px md:text-16px text-yellow-600 font-bold pb-3 fadein">
                                21 Feb, 2024
                            </p></var>
                            <h1 className="text-20px md:text-24px lg:text-28px text-primary font-bold tracking-normal uppercase pb-3 text-left fadein">
                            BRAVE CF's sports development expansion to reach Bulgaria; Mayor of Burgas Dimitar Nikolov, KHK Sports CEO Mohammed Shahid discuss possible BRAVE CF show in the city
                            </h1>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            BRAVE Combat Federation continues to expand into Europe, with Bulgaria touted as a possible future host for a mega event of the most global MMA organization in the world.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={news1} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                BRAVE CF president Mohammed Shahid visited Burgas, Bulgaria, at the invitation of mayor Dimitar Nikolov, as the city looks to become the latest home of the fastest-growing mixed martial arts organization in the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            During the official visit, Mr. Shahid and Mr. Nikolov discussed potential collaboration in the fields of sports, tourism, and business, and Mr. Nikolov also treated the BRAVE CF delegation to a tour of the city’s sports and infrastructure facilities, with the view to hosting a BRAVE CF show in the future. Mr. Nikolov explained the city was ready to host BRAVE CF in the European summer, and is eager to have joint cooperation to make the show happen.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							Burgas is one of the top cities in Bulgaria, and has been credited with hosting K1 events in the past, as MMA and BRAVE CF could bring a new era of sporting structure and development for the city and the country of Bulgaria. Bulgaria could soon join 13 other European countries which have hosted a BRAVE CF show in the past, with the number set to increase to 15 in 2024.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							The organization has confirmed six events in five nations in Europe, with Slovenia, Netherlands, Croatia, Germany, and France all to be featured. Out of those, there will be debut shows in the Netherlands and Croatia, with Bulgaria potentially added to the list.</p>
                        </div>
                    </div>
                </div>
            </div>

            <RecentArticlesForLatest />
            
            <div class="container mx-auto">
                <div class="py-12"><hr /></div>
            </div>


            <Footer />
            
        </body>
    )
}

export default News