import React from "react";
import chairman_banner from "../img/chairman-banner.jpg";
import Footer from '../footer.js';
import shahid_image from "../img/members/shahid-hawk.png";

const ChairmanMessage = () => {
    return (

        <body className="bg-white scroll-smooth">

            <div className="w-full h-96 overflow-hidden relative">
                <img className="absolute inset-0 h-full w-full object-cover" src={chairman_banner} alt="" />
                <div className="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div className="flex h-full items-center justify-center relative">
                    <div className="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div className="grid grid-cols-6 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div className="col-span-6 md:col-span-6 lg:col-span-6 flex items-center">
                                <div className="py-12">
                                    <h4 className="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium uppercase pb-6 js-show-on-scroll">
                                        CHAIRMAN’S Message
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            
            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-6 flex mx-auto md:mx-0 pb-6 md:pb-0">
                            <img src={shahid_image} alt="" className="fadein rounded-lg shadow-md" />
                        </div>
                        <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-6">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                                    KHK Holding was established with the sole purpose of developing a diverse range of industry sectors globally for the advancement of the Kingdom of Bahrain, its economy and community.
                                </p>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                                    With strategic national and global partners, we have unprecedented market knowledgevand access to research, allowing KHK Holding to be the market leader in our chosen industries. We diligently evaluate key markets to find exclusive opportunities which provide the best solutions for our stakeholders, partners, employees and the community. Our portfolio of projects demonstrates our ability to diversify in a range of industries with sustainable growth and strategic, steady expansion. We select our partners with the upmost caution, establishing relationships with business and governments only with an exceptionally reputable standing.
                                </p>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                                    With a steadfast commitment to success and the knowledge and resources to sustain this commitment, KHK Holding will continue to expand into new global markets.
                                </p>

                                <h4 className="text-24px md:text-32px text-black font-secondary-book font-bold uppercase pt-6 fadein">
                                    MOHAMMED SHAHID
                                </h4>
                                <p className="text-14px md:text-16px lg:text-17px text-gray-500 font-primary pb-6 fadein">
                                    CEO, KHK Holding
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="py-12">
                
            </div>


            <Footer />
        
        </body>

    )
}

export default ChairmanMessage