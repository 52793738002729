import React from "react";
import news_bg_3 from "../img/news-bg-3.jpg";
import news1 from "../img/News/news2.jpg";
import Footer from '../footer.js';
import RecentArticlesSideBar from "../recentArticlesSideBar.js";

const News = () => {
    return (
        <body className="bg-white scroll-smooth">

            <div class="w-full h-80 overflow-hidden relative hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src={news_bg_3} alt="" />
                <div class="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div class="flex h-full items-center justify-center relative">
                    <div class="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div class="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div class="col-span-12 md:col-span-9 lg:col-span-6 flex items-center">
                                <div class="py-12">
                                    <h4 class="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        
                                    </h4>
                                    <p class="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <var><p class="text-14px md:text-16px text-yellow-600 font-bold pb-3 fadein">
                                26 Dec, 2023
                            </p></var>
                            <h1 className="text-20px md:text-24px lg:text-28px text-primary font-bold tracking-normal uppercase pb-3 text-left fadein">
                            Asian Cricket Council President and BCCI Honorary Secretary, Jay Shah, Elevates Global Sports Collaboration during Bahrain Visit
                            </h1>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            Manama, Bahrain: Jay Shah, President of the Asian Cricket Council and Secretary of the Board of Control for Cricket in India (BCCI), recently visited the Kingdom of Bahrain to attend two significant events, BRAVE CF 80 and the IMMAF Asian Championship at the Bahrain BRAVE International Combat Week 2023 on the invitation of His Highness Shaikh Khalid bin Hamad al Khalifa, First Deputy Chairman of the Supreme Council for Youth and Sports (SCYS), General Sports Authority (GSA) Chairman and Bahrain Olympic Committee (BOC) President. Mr. Jay Shah’s presence at BRAVE CF event showcased the growing collaboration between cricket and mixed martial arts, underscoring the interconnectedness of various sporting communities on the global stage.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={news1} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                In his meeting with HH Shaikh Khalid bin Hamad Al Khalifa, the patron of BCF and under whose vision, Bahrain cricket has seen stratospheric growth, Mr Shah pledged his support to promoting Bahrain Cricket on the international stage. As the President of the Asian Cricket Council, he has already been doing this in the past couple of years by bringing the first board meeting of the ACC to Bahrain and supporting BCF as it hosted the ACC U-16 tournament in UAE. During the meeting, HH Shaikh Khalid also spoke about building the infrastructure for cricket in the Kingdom of Bahrain including a world-class cricket stadium, an endeavour that Mr Shah also promised to support.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            During the interaction, Mr Mohammad Shahid, Advisor to HH Shaikh Khalid bin Hamad Al Khalifa, CEO of KHK Holding & President of BRAVE CF, briefed Mr Jay Shah and spoke about Brave Combat Federation about the rise of the popularity of BRAVE CF in India. It is the first global mixed martial arts organization to host an event in India having hosted 3 major shows in India in 2017, 2018, and 2019, combined with the strategic partnerships with MX Player and JIOTv, India remains a focus area for the fastest-growing global promotion that has hosted events in 30 nations worldwide. BRAVE CF is also considering launching its first franchise-based league format in India for which a strategic roadmap has been prepared.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							Jay Shah, a key figure in the cricketing world, has been instrumental in steering the success of the recently concluded ICC Men’s World Cup, also met the new Bahrain Cricket Federation board. Mr Shah was honoured by the Chairman of the Advisory Board Mr Mohammad Mansoor along with the BCF Board members.</p>
                        </div>
                    </div>
                </div>
            </div>

            <RecentArticlesSideBar />
            
            <div class="container mx-auto">
                <div class="py-12"><hr /></div>
            </div>


            <Footer />
            
        </body>
    )
}

export default News