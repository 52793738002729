import React, { useState } from "react";
import contact_banner from "../img/contact-banner.jpg";
import Footer from '../footer.js';
import toast, { Toaster } from 'react-hot-toast';

const notifySuccess = () => toast('Thanks For Reaching Out. Our Team Will get back to you for your inquiries');
const notifyError = () => toast('Please fill the form');

const Contact = () => {
    document.title = 'Contact | KHK Holding';
    // State variables for form inputs
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [message, setMessage] = useState("");

    // Function to handle form submission
    const handleSubmit = (e) => {
      e.preventDefault();
      
      // Check if all fields are filled
      if (fullName && phoneNumber && email && country && message) {
        
        
        // Show success message
        notifySuccess();

        // Reset the form fields after submission
        setFullName("");
        setPhoneNumber("");
        setEmail("");
        setCountry("");
        setMessage("");
      } else {
        // Show error message
        notifyError();
      }
    };

    return (
        <body className="bg-white scroll-smooth">
            <div className="w-full h-96 overflow-hidden relative hidden">
                <img className="absolute inset-0 h-full w-full object-cover" src={contact_banner} alt="" />
                <div className="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div className="flex h-full items-center justify-center relative">
                    <div className="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div className="grid grid-cols-6 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div className="col-span-6 md:col-span-6 lg:col-span-6 flex items-center">
                                <div className="py-12">
                                    <h4 className="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium uppercase pb-6 js-show-on-scroll">
                                        CONTACT US
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="col-span-12 flex mx-auto pb-6 md:pb-0">
                            <div className="grid grid-cols-1 md:grid-cols-12 border">
                                <div className="bg-black md:col-span-4 p-10 text-white flex items-center justify-center rounded-tl-md rounded-bl-md">
                                    <div className="bg-primary p-10 -ml-20 xl:-ml-40 h-full rounded-md">
                                        <div className="my-auto">
                                            <h3 className="text-white text-3xl sm:text-4xl leading-normal font-bold tracking-wide fadein mb-20 text-center whitespace-nowrap">
                                                CONTACT US
                                            </h3>
                                            <div className="flex-1 items-center mt-2 mb-10 font-primary fadein">
                                                <p className="text-sm mr-24">
                                                <i className="fa fa-map-marker text-18px md:text-40px text-white mr-2"></i>&nbsp;Road No - 2830, Block No - 428,
                                                </p>
                                                <p className="text-sm ml-10 mt-4">
                                                Building No - 2304
                                                </p>
                                                <p className="text-sm ml-10 mt-4">
                                                Ajayad Building - 7th Floor
                                                </p>
                                                <p className="text-sm ml-10 mt-4">
                                                Office No - 71
                                                </p>
                                                <p className="text-sm ml-10 mt-4">
                                                Seef, Bahrain
                                                </p>
                                            </div>
                                            <div className="flex items-center mt-10 mb-10">
                                                <i className="fa fa-phone text-18px md:text-40px text-white"></i>
                                                <span className="text-sm font-primary fadein ml-4">&nbsp;+973 1700 0038</span>
                                            </div>
                                            <div className="flex items-center mt-10 pb-10">
                                                <i className="fa fa-envelope text-18px md:text-40px text-white"></i>             
                                                <a href="mailto:info@khkholding.com.bh" className="text-sm font-primary fadein ml-4" target="_blank" rel="noopener noreferrer">&nbsp;info@khkholding.com.bh</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <form id="contactForm" className="contact-form md:col-span-8 p-10 bg-black rounded-tr-md rounded-br-md" onSubmit={handleSubmit}>
                                    <h3 className="text-white text-4xl sm:text-5xl leading-normal font-bold font-primary tracking-wide fadein">
                                        GET IN TOUCH
                                    </h3>
                                    <p className="mt-2 mb-4 leading-7 text-gray-200 font-primary fadein">
                                    Feel free to drop us an email anytime.
                                    </p>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-secondary-medium font-bold mb-2" htmlFor="name"></label>
                                            <input type="text" id="name" placeholder="FULL NAME" className="appearance-none bg-gray-200 border rounded-md border-gray-500 w-full text-primary mr-3 p-4 leading-tight focus:outline-none focus:bg-gray-200" required value={fullName} onChange={(e) => setFullName(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-secondary-medium font-bold mb-2" htmlFor="email"></label>    
                                            <input type="email" id="email" placeholder="EMAIL ADDRESS" className="appearance-none bg-gray-200 border rounded-md border-gray-500 w-full text-primary mr-3 p-4 leading-tight focus:outline-none focus:bg-gray-200" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-secondary-medium font-bold mb-2" htmlFor="phone"></label>
                                            <input type="phone" id="phone" placeholder="PHONE" className="appearance-none bg-gray-200 border rounded-md border-gray-500 w-full text-primary mr-3 p-4 leading-tight focus:outline-none focus:bg-gray-200" required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-secondary-medium font-bold mb-2" htmlFor="country"></label>
                                            <input type="country" id="country" placeholder="COUNTRY" className="appearance-none bg-gray-200 border rounded-md border-gray-500 w-full text-primary mr-3 p-4 leading-tight focus:outline-none focus:bg-gray-200" required value={country} onChange={(e) => setCountry(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-6">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-secondary-medium font-bold mb-2" htmlFor="message"></label>
                                            <input type="message" id="message" placeholder="MESSAGE" className="appearance-none bg-gray-200 border rounded-md border-gray-500 w-full text-primary mr-3 px-4 py-16 leading-tight focus:outline-none focus:bg-gray-200" required value={message} onChange={(e) => setMessage(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="flex justify-between w-full px-3">
                                        <input type="submit" id="submit" placeholder="Send Message" className="px-6 py-3 text-12px md:text-14px font-secondary-medium rounded uppercase focus:shadow-outline focus:outline-none text-black bg-white hover:bg-gray-300 hover:text-black border border-primary mt-3 cursor-pointer"/>
                                    </div>
                                    <Toaster/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </body>
    );
}

export default Contact;
