import React from "react";
import news_bg_3 from "../img/news-bg-3.jpg";
import news1 from "../img/News/news7.jpg";
import Footer from '../footer.js';
import RecentArticlesSideBar from "../recentArticlesSideBar.js";
import RecentArticlesForLatest from "../recentArticlesForLatest.jsx";

const News = () => {
    return (
        <body className="bg-white scroll-smooth">

            <div class="w-full h-80 overflow-hidden relative hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src={news_bg_3} alt="" />
                <div class="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div class="flex h-full items-center justify-center relative">
                    <div class="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div class="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div class="col-span-12 md:col-span-9 lg:col-span-6 flex items-center">
                                <div class="py-12">
                                    <h4 class="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        
                                    </h4>
                                    <p class="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <var><p class="text-14px md:text-16px text-yellow-600 font-bold pb-3 fadein">
                                2 Feb, 2024
                            </p></var>
                            <h1 className="text-20px md:text-24px lg:text-28px text-primary font-bold tracking-normal uppercase pb-3 text-left fadein">
                            Mayor of Ljubljana welcomes KHK Sports CEO Mohammed Shahid ahead of major BRAVE CF announcement
                            </h1>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            BRAVE Combat Federation will return to Slovenia for a huge spectacle on April 20.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={news1} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                Ahead of the event’s launch, which happens this Friday, Mohammed Shahid, Advisor to the President of the Bahrain Olympic Committee, CEO of KHK Sports, and president of BRAVE Combat Federation, was received by Mr. Zoran Jankovic, mayor of the country’s capital, Ljubljana. In a special meeting before tonight’s press conference, Mr. Jankovic pledged the support of the city to BRAVE CF’s events, which will take place on April 20 and October 19.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            He also expressed his commitment to the growth of mixed martial arts and BRAVE Combat Federation in Ljubljana, as well as looking forward to the sports and MMA collaboration between Ljubljana and Bahrain through BRAVE CF.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							‘’It was a pleasure to meet Mayor Zoran Jankovic of Ljubljana, Slovenia, today to discuss the upcoming BRAVE CF / WFC event this year. BRAVE CF has traveled to 13 European countries and hosted 22 events in Europe to date. The Mayor has expressed his support to the MMA vision of His Highness Shaikh Khaled bin Hamad Al Khalifa and the sports values of BRAVE CF for the city of Ljubljana’’, said Mohammed Shahid, following the meeting. Zlatko Mahic, president of WFC, BRAVE CF’s partner in Slovenia, expressed his delight at the support of Mr. Jankovic and spoke of how MMA in Slovenia will thrive with BRAVE CF and WFC together.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							‘’Today was an incredible day for the future of MMA in Ljubljana. We had the honor of meeting with Mayor Zoran Jankovic, who not only hosted us, but also showed his enthusiastic support for the sport. We were thrilled by the Mayor’s genuine interest and commitment. With such strong backing, we look forward to more growth and success in the coming years.’’</p>
                        </div>
                    </div>
                </div>
            </div>

            <RecentArticlesForLatest />
            
            <div class="container mx-auto">
                <div class="py-12"><hr /></div>
            </div>


            <Footer />
            
        </body>
    )
}

export default News