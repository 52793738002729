import React from "react";
import news_bg_3 from "../img/news-bg-3.jpg";
import news1 from "../img/News/news4.webp";
import Footer from '../footer.js';
import RecentArticlesSideBar from "../recentArticlesSideBar.js";

const News = () => {
    return (
        <body className="bg-white scroll-smooth">

            <div class="w-full h-80 overflow-hidden relative hidden">
                <img class="absolute inset-0 h-full w-full object-cover" src={news_bg_3} alt="" />
                <div class="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div class="flex h-full items-center justify-center relative">
                    <div class="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div class="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div class="col-span-12 md:col-span-9 lg:col-span-6 flex items-center">
                                <div class="py-12">
                                    <h4 class="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        
                                    </h4>
                                    <p class="text-14px md:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <var><p class="text-14px md:text-16px text-yellow-600 font-bold pb-3 fadein">
                                10 Dec, 2023
                            </p></var>
                            <h1 className="text-20px md:text-24px lg:text-28px text-primary font-bold tracking-normal uppercase pb-3 text-left fadein">
                            German football legend Jérôme Boateng announces his arrival in Bahrain to attend BRAVE CF 80 on December 15th
                            </h1>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            German professional footballer, Jérôme Boateng lands in the Kingdom of Bahrain to attend BRAVE CF 80 taking place on 15 December at the Bahrain BRAVE International Combat Week 2023. During his illustrious career, Boateng has played for Manchester City and Bayern Munich. Currently, he has signed with the French club, Lyon.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={news1} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                TBoateng played in the Germany under-21 side which won the 2009 Euro U-21 Championship and was soon promoted to the national side. Boateng has since accumulated over 70 caps and represented Germany at UEFA Euro 2012, Euro 2016, 2010 World Cup, 2014 World Cup, and 2018 World Cup. He was a key member of his country's victory in the 2014 World Cup.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            BRAVE CF attracts a host of diplomats, global businessmen, athletes, artists, celebrities, and more as the organization has made history as the only global mixed martial arts organization to host events in 30 different nations. The organization has more diverse global talent than any MMA organization in the world, featuring nearly 800 athletes from over 80 countries across the globe.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							Since its inception in 2016 under the leadership of His Highness Sheikh Khaled bin Hamad Al Khalifa, BRAVE CF has secured strategic relations and recognition from governments, promotions, businesses, and organizations to enhance the status of the sport and its athletes. In 2023, the organization exceeded viewership records with over 500,000 viewers tuning in solely through its homegrown digital platforms. The organization broadcasts its events across 160+ countries to 848 million households, reaching audiences through various platforms and television channels. On the digital front, BRAVE CF offers live streams on its YouTube channel (@bravemmaf) and its own OTT platform, Brave TV.</p>
							<p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
							In 2023, BRAVE CF expanded its reach by establishing strategic partnerships with leading OTT platforms across Asia and Africa. These include Jio and MX Player in India, Vidio in Indonesia, and Airtel TV in Nigeria. These collaborations demonstrate BRAVE CF's commitment to digitalization and its dedication to bringing its high-quality action to fans worldwide.</p>
                        </div>
                    </div>
                </div>
            </div>

            <RecentArticlesSideBar />
            
            <div class="container mx-auto">
                <div class="py-12"><hr /></div>
            </div>


            <Footer />
            
        </body>
    )
}

export default News