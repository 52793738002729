import React from "react";
import './About.css';
import Footer from '../footer.js';
import about_header_bg from "../img/About/about-1.jpg";
import group_logo_1 from "../img/group/1.png";
import group_logo_2 from "../img/group/2.png";
import group_logo_3 from "../img/group/3.png";
import group_logo_4 from "../img/group/4.png";
import group_logo_5 from "../img/group/5.png";
import group_logo_6 from "../img/group/6.png";
import group_logo_7 from "../img/group/7.png";
import group_logo_8 from "../img/group/8.png";
import group_logo_9 from "../img/group/9.png";
import group_logo_10 from "../img/group/10.png";
import group_logo_11 from "../img/group/11.png";
import group_logo_12 from "../img/group/12.png";
import group_logo_13 from "../img/group/13.png";
import group_logo_14 from "../img/group/14.png";
import group_logo_15 from "../img/group/15.png";
import group_logo_16 from "../img/group/16.png";
import group_logo_17 from "../img/group/17.png";
import group_logo_18 from "../img/group/18.png";
import group_logo_19 from "../img/group/19.png";
import group_logo_20 from "../img/group/20.png";
import group_logo_21 from "../img/group/21.png";
import about_main from "../img/About/seef.png";
import vision from "../img/About/1.png";
import mission from "../img/About/2.png";
import ProvidingOpportunities from "../img/Providing Opportunities/Providing-Opportunities_01.jpg";
import ProvidingOpportunities2 from "../img/Providing Opportunities/Providing-Opportunities_02.jpg";
import shahid_image from "../img/members/mohdshahid.jpg";

const About = () => {
    document.title = 'About | KHK Holding';
    return (
        <body className="bg-white scroll-smooth">
           
            <div className="w-full h-96 overflow-hidden relative hidden">
                <img className="absolute inset-0 h-full w-full object-cover" src={about_header_bg} alt="" />
                <div className="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div className="flex h-full items-center justify-center relative">
                    <div className="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div className="grid grid-cols-6 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div className="col-span-6 md:col-span-6 lg:col-span-6 flex items-center">
                                <div className="py-12">
                                    <h4 className="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        ABOUT US
                                    </h4>
                                    {/* <p className="text-14px md:text-16px lg:text-17px text-white font-primary pb-6 js-show-on-scroll">
                                    KHK Holding creates and manages a diversified portfolio of companies and subsidiaries operating across 11 countries and a proven track record of success.
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    <div className="grid-cols-1">
                        <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-left fadein">
                            About Us
                        </h4></var>
                    </div>
                    <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify md:text-left fadein">
                                Founded in 2015, KHK Holding Company is proud of the legacy and diversification in a multitude of sectors such as Sports, Media, Finance, Technology, Commodities, and Real Estate. Our success results from a well-defined strategy combined with informed business choices and professional management.  Adapting to an ever-changing business landscape, investing in successful partnerships and entrepreneurs across all industries.
                                </p>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify md:text-left fadein">
                                KHK Holding creates and manages a diversified portfolio of companies and subsidiaries operating across 11 countries and a proven track record of success. KHK Holding prides itself on its culture of integrity, innovation, development, and value creation and works with only the best to maintain its position as a market leader.
                                </p>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 hidden">
                            <img src={about_main} alt="" className="fadein rounded-lg shadow-md" />
                        </div>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                    <img src={about_main} alt="" className="fadein shadow-md" />
                </div>
            </div>

            <section className="mt-10">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    <div className="grid-cols-1">
                        <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-left fadein">
                            Our Companies
                        </h4></var>
                    </div>
                    <var><p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify md:text-left fadein">
                        We invest in entrepreneurs’ ideas and value systems are consistent with those of our founders. KHK Holdings partners with innovative leaders with future thinking ideas, professional management teams with a demonstrated ability to contribute to the achievement of our strategic goals.
                    </p></var>
                    <div className="max-w-5xl flex-col mx-auto text-center">
                        <section className="py-12" id="logoMarqueeSection">
                            <div className="default-content-container flex items-center">
                                <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
                                    <div className="marquee">
                                        {/* <span><img src={group_logo_1} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                        <span><img src={group_logo_2} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_3} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_4} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_5} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_6} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_7} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_8} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_9} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_10} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_11} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_12} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_13} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_14} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_21} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        {/* <span><img src={group_logo_16} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_17} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_18} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_19} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_20} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                        {/* <span><img src={group_logo_15} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                    </div>
                                    <div className="marquee">
                                        {/* <span><img src={group_logo_1} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                        <span><img src={group_logo_2} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_3} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_4} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_5} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_6} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_7} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_8} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_9} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_10} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_11} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_12} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_13} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_14} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_21} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        {/* <span><img src={group_logo_16} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_17} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_18} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_19} title="" alt="" className="marqueelogo w-auto max-w-none" /></span>
                                        <span><img src={group_logo_20} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                        {/* <span><img src={group_logo_15} title="" alt="" className="marqueelogo w-auto max-w-none" /></span> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    {/* <div className="grid-cols-1">
                        <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-left fadein">
                            Our Vision
                        </h4></var>
                    </div> */}
                    <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-0">
                            <div>
                                <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-left fadein">
                                    Our Vision
                                </h4></var>
                                <var><p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-left md:text-left fadein">
                                "To create valuable connections between nations and develop industry sectors for the benefit of the Kingdom’s economy, infrastructure and community."
                                </p></var>
                                {/* <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                                KHK Holding creates and manages a diversified portfolio of companies and subsidiaries operating across 11 countries and a proven track record of success. KHK Holding prides itself on its culture of integrity, innovation, development, and value creation and works with only the best to maintain its position as a market leader.
                                </p> */}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                            <img src={vision} alt="" className="fadein rounded-lg shadow-md" />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-4 md:gap-8 pb-6 md:pb-10 lg:pb-6 xl:pb-0 lg:mt-20">
                        <div className="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                            <img src={mission} alt="" className="fadein rounded-lg shadow-md" />
                        </div>
                        <div className="col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-0">
                            <div>
                                <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-right fadein">
                                    Our Mission
                                </h4></var>
                                <var><p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-right md:text-right fadein">
                                "KHK Holding honours its resounding commitment to serving The Kingdom of Bahrain, through identifying and developing industries with vast potential, recognized through exceptional market knowledge, respected partnerships and a strong global presence."
                                </p></var>
                                {/* <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                                KHK Holding creates and manages a diversified portfolio of companies and subsidiaries operating across 11 countries and a proven track record of success. KHK Holding prides itself on its culture of integrity, innovation, development, and value creation and works with only the best to maintain its position as a market leader.
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                    <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12 flex items-center mx-auto md:mx-0 pb-0 md:pb-0 px-0 md:px-0">
                        <div>
                            <var><h4 className="text-20px md:text-28px lg:text-28px xl:text-3xl text-primary font-secondary-medium pb-6 text-left fadein">
                                Chairman's Message
                            </h4></var>
                            <span className="text-40px md:text-38px lg:text-38px xl:text-6xl text-yellow-700 font-secondary-medium pb-6 text-left fadein">&ldquo;</span>
                            <var><p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                            KHK Holding was established with the sole purpose of developing a diverse range of industry sectors globally for the advancement of the Kingdom of Bahrain, its economy and community.
                            </p></var>
                            <var><p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                            With strategic national and global partners, we have unprecedented market knowledgevand access to research, allowing KHK Holding to be the market leader in our chosen industries. We diligently evaluate key markets to find exclusive opportunities which provide the best solutions for our stakeholders, partners, employees and the community.
                            </p></var>
                        </div>
                    </div>
                    {/* <div className="col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                        <img src={about_main} alt="" className="fadein rounded-lg shadow-md" />
                    </div> */}
                </div>
            </div>

            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-6 md:pb-6 lg:pb-16">
                <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                    <div className="col-span-12 md:col-span-8 lg:col-span-8 xl:col-span-8 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-0">
                        <div>
                            <var><p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                            Our portfolio of projects demonstrates our ability to diversify in a range of industries with sustainable growth and strategic, steady expansion. We select our partners with the upmost caution, establishing relationships with business and governments only with an exceptionally reputable standing.
                            </p></var>
                            <var><p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                            With a steadfast commitment to success and the knowledge and resources to sustain this commitment, KHK Holding will continue to expand into new global markets.
                            </p></var>
                            <span className="text-40px md:text-38px lg:text-38px xl:text-6xl text-yellow-700 font-secondary-medium pb-6 text-right flex justify-center fadein">&rdquo;</span>
                            <h4 className="text-24px md:text-32px text-yellow-700 font-bold uppercase pt-6 fadein whitespace-nowrap">
                                MOHAMMED SHAHID
                            </h4>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 fadein">
                                CEO, KHK Holding
                            </p>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-4 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                        <img src={shahid_image} alt="" className="fadein rounded-full shadow-md h-60 w-60" />
                    </div>
                </div>
            </div>
            

            {/* <div className="py-12">
                <hr />
            </div> */}

            
            <div className="w-full bg-white hidden">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="col-span-12 md:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0">
                            <img src={ProvidingOpportunities} alt="" className="fadein rounded-lg shadow-md" />
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-6">
                            <div>
                                <h4 className="text-24px md:text-32px lg:text-32px text-black font-secondary-medium tracking-normal pb-6 text-center md:text-left fadein">
                                    PROVIDING OPPORTUNITY
                                </h4>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-center md:text-left fadein">
                                    We invest in entrepreneurs’ ideas and value systems are consistent with those of our founders. KHK Holdings partners with innovative leaders with future thinking ideas, professional management teams with a demonstrated ability to contribute to the achievement of our strategic goals.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="py-12 hidden">
                <hr />
            </div>

            <div className="w-full bg-white hidden">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 py-6 md:py-6 lg:py-16">
                    <div className="grid grid-cols-12 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                        <div className="col-span-12 md:col-span-6 flex mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-6">
                            <div>
                                <h4 className="text-24px md:text-32px lg:text-32px text-black font-secondary-medium tracking-normal text-center fadein">
                                    OUR VISION
                                </h4>
                                <hr className="border-t-2 border-gray-500"></hr>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pt-6 pb-6 text-center fadein italic">
                                    <span className="text-primary text-14px md:text-16px lg:text-17px font-extrabold">&ldquo;</span> To create valuable connections between nations and develop industry sectors for the benefit of the Kingdom’s economy, infrastructure and community.<span className="text-primary text-14px md:text-16px lg:text-17px font-extrabold">&rdquo;</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex mx-auto md:mx-0 pb-6 md:pb-0 px-0 md:px-6">
                            <div>
                                <h4 className="text-24px md:text-32px lg:text-32px text-black font-secondary-medium tracking-normal text-center fadein">
                                    OUR MISSION
                                </h4>
                                <hr className="border-t-2 border-gray-500"></hr>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pt-6 pb-6 text-center fadein italic">
                                    <span className="text-primary text-14px md:text-16px lg:text-17px font-extrabold">&ldquo;</span> KHK Holding honours its resounding commitment to serving The Kingdom of Bahrain, through identifying and developing industries with vast potential, recognized through exceptional market knowledge, respected partnerships and a strong global presence.<span className="text-primary text-14px md:text-16px lg:text-17px font-extrabold">&rdquo;</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-12 hidden">
                <hr />
            </div>

            
            

            <Footer />
        
        </body>
    )
}

export default About