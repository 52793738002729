import React from "react";
import Industry_01 from "../img/Industry_01.jpg";
import sports from "../img/industries/sportskhk.png";
import media from "../img/industries/mediaSector.jpg";
import real_estate from "../img/industries/realEstateSector.jpg";
import finance from "../img/industries/financeSector.jpg";
import technology from "../img/industries/technologykhk.png";
import Footer from '../footer.js';

const Industries = () => {
    document.title = 'Industries | KHK Holding';
    return (
        
        <main className="bg-white scroll-smooth">

            <div className="w-full h-96 overflow-hidden relative hidden">
                <img className="absolute inset-0 h-full w-full object-cover" src={Industry_01} alt="" />
                <div className="absolute inset-0 bg-gray-900 bg-opacity-40"></div>
                <div className="flex h-full items-center justify-center relative">
                    <div className="max-w-sm md:max-w-2xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-8xl mx-auto px-6 md:px-0 h-96 flex items-center">
                        <div className="grid grid-cols-6 gap-4 md:gap-8 py-6 md:py-10 lg:py-6 xl:py-0">
                            <div className="col-span-6 md:col-span-6 lg:col-span-6 flex items-center">
                                <div className="py-12">
                                    <h4 className="text-24px md:text-32px xl:text-5xl text-white font-secondary-medium pb-6 js-show-on-scroll">
                                        INVESTMENT SECTORS
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                <div className="grid-cols-1">
                    <var><h4 className="text-20px md:text-28px lg:text-28px text-4xl md:text-3xl xl:text-3xl text-primary font-secondary-medium pb-0 text-center md:text-left fadein">
                        Investment Sectors
                    </h4></var>
                </div>
            </div>
            

            <div id="sports" className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <h4 className="text-24px md:text-32px lg:text-32px text-yellow-700 font-bold tracking-widest pb-6 text-center md:text-left fadein">
                                SPORTS
                            </h4>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            In the realm of sports, KHK Holding leads with a visionary approach to investments. Whether it's sports franchises, events, or infrastructure, our strategic investments aim to contribute to the growth and success of the sports industry.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={sports} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                Our investments in this sector include sports franchises, media rights, broadcasting networks, live events, talent agencies, and content production studios. We recognize the power of sports and entertainment to drive engagement, loyalty, and revenue across multiple platforms and distribution channels. Our approach to investing in sports and entertainment is rooted in a deep understanding of consumer preferences, market dynamics, and industry trends. We seek to identify opportunities that offer compelling value propositions, sustainable growth potential, and differentiated competitive advantages.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        {/* <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            In the realm of sports, KHK Holding leads with a visionary approach to investments. Whether it's sports franchises, events, or infrastructure, our strategic investments aim to contribute to the growth and success of the sports industry.</p>
                        </div> */}
                    </div>
                </div>
            </div>

            <div id="media" className="py-12">
                <hr />
            </div>

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <h4 className="text-24px md:text-32px lg:text-32px text-yellow-700 font-bold tracking-widest pb-6 text-center md:text-left fadein">
                                MEDIA 
                            </h4>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            KHK Holding recognizes the transformative power of media in today's interconnected world. Our investments in media ventures encompass a diverse range, from digital platforms to traditional outlets.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                Encompass traditional and digital media platforms, telecommunications providers, advertising agencies, and content creators. We believe in the transformative power of media and communication to inform, inspire, and entertain audiences around the world. Our approach to investing in media and communication is guided by a focus on content quality, audience engagement, and monetization strategies. We seek to partner with companies that are at the forefront of innovation, leveraging technology to create compelling content, deliver personalized experiences, and build sustainable business models for the digital age.</p>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={media} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        {/* <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            In the realm of sports, KHK Holding leads with a visionary approach to investments. Whether it's sports franchises, events, or infrastructure, our strategic investments aim to contribute to the growth and success of the sports industry.</p>
                        </div> */}
                    </div>
                </div>
            </div>

            <div id="realEstate" className="py-12">
                <hr />
            </div>

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <h4 className="text-24px md:text-32px lg:text-32px text-yellow-700 font-bold tracking-widest pb-6 text-center md:text-left fadein">
                                REAL ESTATE
                            </h4>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            Real estate is a tangible asset class that offers the potential for long-term capital appreciation, rental income, and portfolio diversification</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={real_estate} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                Our investments in real estate encompass residential, commercial, industrial, and hospitality properties across diverse markets and geographies. We target opportunities that offer attractive risk-adjusted returns, strong cash flow potential, and value-add propositions. Our approach to real estate investing is guided by thorough due diligence, rigorous underwriting standards, and active asset management. We seek to unlock value through strategic acquisitions, development projects, and operational enhancements, while mitigating risks through prudent financial management and disciplined execution.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        {/* <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            In the realm of sports, KHK Holding leads with a visionary approach to investments. Whether it's sports franchises, events, or infrastructure, our strategic investments aim to contribute to the growth and success of the sports industry.</p>
                        </div> */}
                    </div>
                </div>
            </div>

            <div id="finance" className="py-12">
                <hr />
            </div>

            <div className="w-full bg-white">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <h4 className="text-24px md:text-32px lg:text-32px text-yellow-700 font-bold tracking-widest pb-6 text-center md:text-left fadein">
                                TRADING
                            </h4>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            KHK Holding recognizes the transformative power of media in today's interconnected world. Our investments in media ventures encompass a diverse range, from digital platforms to traditional outlets.</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                we seek investment opportunities across a broad spectrum of financial services. This includes traditional banking institutions, insurance companies, investment banks, asset management firms, and fintech startups. Our portfolio reflects our belief in the importance of financial inclusion, innovation, and responsible stewardship of capital. We invest in companies that are reshaping the financial landscape, whether through technological innovation, regulatory compliance, or customer-centric solutions. By diversifying our investments within the finance sector, we aim to capture opportunities across different markets and geographies while managing risk effectively.</p>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={finance} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        {/* <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            In the realm of sports, KHK Holding leads with a visionary approach to investments. Whether it's sports franchises, events, or infrastructure, our strategic investments aim to contribute to the growth and success of the sports industry.</p>
                        </div> */}
                    </div>
                </div>
            </div>
            <div id="technology" className="py-12">
                <hr />
            </div>

            <div className="w-full bg-white pb-10">
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-16">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        <div>
                            <h4 className="text-24px md:text-32px lg:text-32px text-yellow-700 font-bold tracking-widest pb-6 text-center md:text-left fadein">
                                TECHNOLOGY
                            </h4>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            Innovation is the key to staying ahead. From groundbreaking startups to established industry leaders, we invest in companies poised to revolutionize the way we live, work, and connect. Explore opportunities at the forefront of digital transformation and technological advancement</p>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pb-0 md:pb-0 lg:pb-0">
                    <div className="grid grid-cols-12 gap-6 md:gap-12 pb-0 md:pb-0 lg:pb-0 xl:pb-0">
                        <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                            <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                                <img src={technology} alt="" className="fadein w-full mx-auto md:ml-auto md:mr-0 rounded-lg shadow-md" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 flex items-center pb-6 md:pb-0">
                            <div>
                                <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                                from cutting-edge startups to established tech giants, our portfolio includes firms involved in software development, artificial intelligence, cloud computing, cybersecurity, e-commerce, and digital transformation. We believe in supporting visionary entrepreneurs who are revolutionizing the way we live, work, and interact with technology. Our investments in this sector aim to capitalize on emerging trends and technologies, creating value for our stakeholders and driving sustainable growth in the digital age.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="max-w-6xl md:max-w-4xl lg:max-w-4xl xl:max-w-7xl 2xl:max-w-10xl mx-auto px-6 md:px-6 lg:px-0 pt-6 md:pt-6 lg:pt-6">
                    <div className="col-span-12 md:col-span-6 flex items-center md:mx-0 pb-6 md:pb-0">
                        {/* <div>
                            <p className="text-14px md:text-16px lg:text-17px text-black font-primary pb-6 text-justify fadein">
                            In the realm of sports, KHK Holding leads with a visionary approach to investments. Whether it's sports franchises, events, or infrastructure, our strategic investments aim to contribute to the growth and success of the sports industry.</p>
                        </div> */}
                    </div>
                </div>
            </div>

            <Footer />
            
        </main>
        
    )
}


export default Industries